import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import Truncate from 'react-truncate';
import { useTranslation } from 'react-i18next';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import NavigationIcon from '@material-ui/icons/Navigation';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ApartmentIcon from '@material-ui/icons/Apartment';
import FolderIcon from '@material-ui/icons/Folder';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getContactsByPhone } from 'frontend-components/lib/helpers';
import { usePermissions } from 'frontend-components';

import {
  TextContainer,
  StyledCard,
  Title,
  LockIconContainer,
  StyledNearMe,
  StyledPhoneIcon,
  StyledDescription,
  StyledCameraAltIcon,
  StyledData,
  ClassificationCodeContainer,
  ActionDoneInfo,
} from './Card.styles';

import {
  createTileContent,
  createDepartment,
  DEFAULT_TILE_LINES,
} from './TileContent';
import {
  ButtonsContainer,
  StyledTextList,
  StyledButton,
  StyledIconLine,
} from '../../views/SelectCustomerView/CustomersListy.styles';
import {
  getActionIcon,
  getActionName,
  getNextActionReportPath,
} from '../../helpers/actions';
import { DAY_FILTER_VALUES } from '../../views/SelectCustomerView/DayFilter';
import { contactPersonDataShape } from '../../helpers/clientDataPropTypes';
import ClassificationCode from '../ClassificationCode/ClassificationCode';
import CardCollapsed from './CardCollapsed';
import {
  isDelegated,
  isReadable,
  isWritable,
  isEditable,
} from '../../helpers/permissions';

const Card = ({
  id,
  actionId,
  path,
  filterBy,
  tileLines,
  showDepartment,
  showCustomerNumber,
  titleLines,
  description,
  actionTime: { doAt, startTime, endTime },
  actionType,
  reportType,
  clientDetails: {
    name,
    distance,
    lat,
    lng,
    address,
    department,
    generalPhoneNumber,
    classification,
    clientNumber,
  },
  applicationReportId,
  actionDone,
  contactPersons,
  contactPersonId,
  onLinkClick,
  owned,
  access,
  handlePhoneButtonClick,
  handleNavigationButtonClick,
  handleGalleryButtonClick,
  isExpanded,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const showLockIcon = isReadable(access) || isDelegated(access);
  const showLockOpenIcon =
    owned === false && (isWritable(access) || isEditable(access));
  const isDateDisplayed =
    filterBy === DAY_FILTER_VALUES.ALL || filterBy === DAY_FILTER_VALUES.DONE;
  const { addDisabled } = usePermissions(access, owned);

  const formatActionDate = (date) => {
    return date.split('-').reverse().join('-');
  };

  return (
    <>
      <Link
        to={`${path}/${id}`}
        onClick={() => {
          onLinkClick && onLinkClick(id);
        }}
      >
        <StyledCard data-testid="client-card">
          {showLockIcon && (
            <LockIconContainer data-testid="lock-icon">
              <LockOutlinedIcon />
            </LockIconContainer>
          )}
          {showLockOpenIcon && (
            <LockIconContainer data-testid="lock-icon">
              <LockOpenOutlinedIcon />
            </LockIconContainer>
          )}
          <ClassificationCodeContainer>
            <ClassificationCode code={classification} />
          </ClassificationCodeContainer>
          <TextContainer>
            <Title data-testid="action-title">
              <Truncate lines={titleLines} ellipsis={<span>...</span>}>
                {name}
              </Truncate>
            </Title>
            <StyledData>
              {filterBy && (
                <StyledIconLine data-testid={actionType}>
                  {getActionIcon(actionType)}
                  {actionType !== 'other' && actionType !== 'phonecall' && (
                    <StyledTextList data-testid="action-time">
                      {isDateDisplayed && (
                        <>
                          {formatActionDate(doAt)}
                          <br />
                        </>
                      )}
                      {startTime.slice(0, 5)} - {endTime.slice(0, 5)}
                    </StyledTextList>
                  )}
                  {actionType === 'phonecall' && (
                    <StyledTextList data-testid="action-time">
                      {isDateDisplayed && (
                        <>
                          {formatActionDate(doAt)}
                          <br />
                        </>
                      )}
                      {startTime.slice(0, 5)}
                    </StyledTextList>
                  )}
                  {actionType === 'other' && (
                    <StyledTextList data-testid="action-date">
                      {formatActionDate(doAt)}
                    </StyledTextList>
                  )}
                </StyledIconLine>
              )}
            </StyledData>

            {filterBy && (
              <StyledDescription
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push(
                    getNextActionReportPath(
                      id,
                      reportType,
                      actionId,
                      applicationReportId
                    )
                  );
                }}
              >
                <div>
                  <Truncate lines={2}>
                    {description || t(getActionName(actionType))}
                  </Truncate>
                </div>
                <ChevronRightIcon />
              </StyledDescription>
            )}
            {!filterBy && showDepartment && department && (
              <StyledIconLine>
                <ApartmentIcon fontSize="small" />
                {createDepartment(department)}
              </StyledIconLine>
            )}
            {!filterBy && address && (
              <StyledIconLine>
                <LocationOnIcon fontSize="small" />
                {createTileContent(tileLines || DEFAULT_TILE_LINES, address)}
              </StyledIconLine>
            )}
            {distance && (
              <StyledIconLine>
                <NavigationIcon fontSize="small" />
                <StyledTextList data-testid="client-distance">
                  {distance}
                </StyledTextList>
              </StyledIconLine>
            )}

            {!filterBy && showCustomerNumber && clientNumber && (
              <StyledIconLine>
                <FolderIcon fontSize="small" />
                <div>{clientNumber}</div>
              </StyledIconLine>
            )}
          </TextContainer>
        </StyledCard>
      </Link>
      {!actionDone ? (
        <ButtonsContainer noItems={!contactPersons || !contactPersons.length}>
          <StyledButton
            visit
            disabled={!address}
            onClick={() => {
              handleNavigationButtonClick(lat, lng, address);
            }}
          >
            <StyledNearMe fontSize="small" />
            <p>{t('customer_list_navigate')}</p>
          </StyledButton>
          <StyledButton
            disabled={
              !getContactsByPhone(contactPersons)?.length && !generalPhoneNumber
            }
            onClick={handlePhoneButtonClick}
          >
            <StyledPhoneIcon fontSize="small" />
            <p>{t('customer_list_call')}</p>
          </StyledButton>
          {!addDisabled && (
            <StyledButton disabled={false} onClick={handleGalleryButtonClick}>
              <StyledCameraAltIcon fontSize="small" />
              <p>{t('camera_button_dca')}</p>
            </StyledButton>
          )}
        </ButtonsContainer>
      ) : (
        <ActionDoneInfo>
          <CheckCircleIcon />
          <div>{t('actions_list_done')}</div>
        </ActionDoneInfo>
      )}
      {filterBy && (
        <CardCollapsed
          tileLines={tileLines}
          address={address}
          department={department}
          showDepartment={showDepartment}
          contactPersonId={contactPersonId}
          contactPersons={contactPersons}
          clientNumber={clientNumber}
          isExpanded={isExpanded}
        />
      )}
    </>
  );
};

Card.propTypes = {
  id: PropTypes.string,
  actionId: PropTypes.string,
  path: PropTypes.string,
  filterBy: PropTypes.string,
  tileLines: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  actionTime: PropTypes.shape({
    doAt: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
  actionType: PropTypes.string,
  reportType: PropTypes.string,
  clientDetails: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    distance: PropTypes.string,
    lat: PropTypes.string,
    lng: PropTypes.string,
    department: PropTypes.string,
    generalPhoneNumber: PropTypes.string,
    classification: PropTypes.string,
    clientNumber: PropTypes.string,
  }),
  applicationReportId: PropTypes.string,
  actionDone: PropTypes.bool,
  contactPersons: PropTypes.arrayOf(contactPersonDataShape),
  contactPersonId: PropTypes.string,
  titleLines: PropTypes.number,
  showDepartment: PropTypes.bool,
  showCustomerNumber: PropTypes.bool,
  owned: PropTypes.bool,
  access: PropTypes.string,
  onLinkClick: PropTypes.func,
  handlePhoneButtonClick: PropTypes.func,
  handleNavigationButtonClick: PropTypes.func,
  handleGalleryButtonClick: PropTypes.func,
  isExpanded: PropTypes.bool,
};

export default Card;
