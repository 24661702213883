import { createSlice } from '@reduxjs/toolkit';
import { isAfter } from 'date-fns';
import { formatDate } from 'frontend-components/lib/helpers';

import {
  createEmptyVisitNote,
  createEmptyCallNote,
  createEmptyRemarkNote,
  deleteVisitNoteStore,
  deleteCallNoteStore,
  deleteRemarkNoteStore,
  setCallNoteContactPerson,
  updateCallNoteStore,
  updateNoteStore,
  updateRemarkNoteStore,
  setVisitNoteContactPerson,
  setRemarkNoteContactPerson,
  deleteVisitById,
  updateFileDescriptionStore,
  deleteAttachmentByIdStore,
} from '../actions/notesActions';
import { cancelVisitById } from '../actions/visitsActions';
import {
  createNewApplication,
  updateApplication,
  deleteApplicationLocally,
  updateApplicationsSection,
  setApplicationInterflonLubricant,
} from '../actions/applicationActions';
import {
  createContactPerson,
  addContactPersonToApplication,
  deleteContactPersonInStore,
  editContactPerson,
  deleteApplicationContactPerson,
  setApplicationContactPerson,
} from '../actions/contactPersonActions';
import { fetchClientDataById } from '../actions/clientDataActions';
import {
  ACTION_ADD_PHOTO_COMMIT,
  addPhoto,
  editPhoto,
  deletePhoto,
  addAttachmentImage,
} from '../actions/photoActions';

import { addVideo, editVideo, deleteVideo, addAttachmentVideo } from './video';
import { REPORT_TYPES } from '../../constants/noteTypes';
import {
  createEmptyApplicationNote,
  updateApplicationNoteStore,
  attachApplicationNote,
  deleteApplicationNoteStore,
  addNextActionToApplicationNote,
  deleteNextActionFromApplicationNote,
  addProductToApplicationNote,
  addDemoedProductsPerApplicationNote,
  removeDemoedProductFromApplicationNote,
  setApplicationNoteContactPerson,
  applicationNoteEditionDone,
} from '../actions/applicationNoteActions';
import { updateQuotationHistory } from '../actions/quotationActions';
import {
  addNextActionToCallNote,
  addNextActionToVisitNote,
  addNextActionToRemarkNote,
  deleteNextActionFromCallNote,
  deleteNextActionFromOpenActions,
  deleteNextActionFromVisitNote,
  deleteNextActionFromRemarkNote,
  updateNextActionInOpenActions,
  addNextActionToOpenActions,
} from '../actions/nextActionActions';
import {
  addProductsPerCallNote,
  addDemoedProductsPerNote,
  removeDemoedProduct,
  addProductToVisit,
  updateDemoedProductsDate,
} from '../actions/demoProductsActions';
import {
  updateClientName,
  deleteClientData,
  createClientStore,
} from '../actions/clientDetailsActions';
import {
  addGalleryItemToApplication,
  removeGalleryItem,
  removeGalleryItemFromApplication,
  editGalleryItem,
} from '../../helpers/storeHelpers/gallery';
import {
  addDemoProductsToNote,
  addNextActionToNote,
  deleteNote,
  updateNote,
} from '../../helpers/storeHelpers/notes';
import {
  APPLICATION_SECTION_NAMES,
  updateApplicationSection,
  removeOpenActionByApplication,
  markApplicationEdited,
} from '../../helpers/storeHelpers/applications';

const ADD_VIDEO_ROLLBACK = 'videos/addVideo/rollback';

const clientDataSlice = createSlice({
  name: 'clientData',
  initialState: {
    data: {},
    loadingStatus: {},
  },
  reducers: {
    clearPhotoInApplication: (state, action) => {
      const { clientId, photoId, applicationId } = action.payload;

      removeGalleryItemFromApplication(state, clientId, applicationId, photoId);
    },
  },
  extraReducers: {
    [createClientStore]: (state, action) => {
      const { clientId, clientData } = action.payload;
      state.data[clientId] = { ...clientData };
      state.loadingStatus[clientId] = 'fetched';
    },
    [deleteContactPersonInStore]: (state, action) => {
      const { contactPersonId, clientId } = action.payload;
      const contactPersonIdx = state.data[
        clientId
      ].contactPersons.data.findIndex((c) => c.id === contactPersonId);
      if (contactPersonIdx !== -1) {
        state.data[clientId].contactPersons.data[
          contactPersonIdx
        ].isDeleted = 1;
      }
    },
    [createContactPerson]: (state, action) => {
      const { clientId } = action.payload.contactPersonData;
      const { contactPersonId } = action.payload;
      const contactPerson = {
        ...action.payload.contactPersonData,
        id: contactPersonId,
        isDeleted: 0,
      };
      state.data[clientId].contactPersons.data.push(contactPerson);
      state.data[clientId].contactPersons.total =
        state.data[clientId].contactPersons.data.length;
    },
    [addContactPersonToApplication]: (state, action) => {
      const { clientId, applicationId, contactPersonId } = action.payload;
      const application = state.data[clientId].applications.data.find(
        (application) => application.id === applicationId
      );
      if (application) {
        if (application.contacts) {
          application.contacts.push(contactPersonId);
        } else {
          application.contacts = [contactPersonId];
        }
        application.edited = true;
      }
    },
    [editContactPerson]: (state, action) => {
      const { clientId } = action.payload.contactPersonData;
      const { contactPersonId } = action.payload;
      const contactPerson = {
        ...action.payload.contactPersonData,
        id: contactPersonId,
        isDeleted: 0,
      };
      const contactPersonIdx = state.data[
        clientId
      ].contactPersons.data.findIndex(
        (contact) => contact.id === contactPersonId
      );
      if (contactPersonIdx !== -1) {
        state.data[clientId].contactPersons.data[contactPersonIdx] =
          contactPerson;
      }
    },
    [createEmptyVisitNote]: (state, action) => {
      const { noteId, clientId } = action.payload;
      const notes = state.data[clientId].visits;
      state.data[clientId].visits.data.unshift({
        id: noteId,
        clientId,
        visitDate: formatDate(new Date()),
        note: '',
        isNoShow: false,
        isDraft: true,
        contactPersonId: '',
        nextAction: null,
        demoProducts: [],
        edited: false,
      });
      notes.total = notes.data.length;
    },
    [createEmptyCallNote]: (state, action) => {
      const { noteId, clientId } = action.payload;
      const notes = state.data[clientId].phoneCalls;
      notes.data.unshift({
        id: noteId,
        clientId,
        phoneCallDate: formatDate(new Date()),
        note: '',
        isNoShow: false,
        isDraft: true,
        contactPersonId: '',
        nextAction: null,
        demoProducts: [],
        edited: false,
      });
      notes.total = notes.data.length;
    },
    [createEmptyRemarkNote]: (state, action) => {
      const { noteId, clientId } = action.payload;
      const notes = state.data[clientId].remarks;
      notes.data.unshift({
        id: noteId,
        clientId,
        remarkDate: formatDate(new Date()),
        note: '',
        contactPersonId: '',
        nextAction: null,
        edited: false,
      });
      notes.total = notes.data.length;
    },
    [createEmptyApplicationNote]: (state, action) => {
      const { noteId, clientId, applicationId } = action.payload;
      if (!state.data[clientId].applicationNotes) {
        state.data[clientId].applicationNotes = {
          total: 0,
          data: [],
        };
      }
      const notes = state.data[clientId].applicationNotes;
      notes.data.unshift({
        id: noteId,
        applicationId,
        clientId,
        noteDate: formatDate(new Date()),
        note: '',
        contactPersonId: '',
        nextAction: null,
        demoProducts: [],
        edited: false,
      });
      notes.total = notes.data.length;

      const application = state.data[clientId].applications.data.find(
        ({ id }) => id === applicationId
      );

      if (application) {
        if (application?.notes) {
          application.notes = [noteId, ...application.notes];
        } else {
          application.notes = [noteId];
        }
      }
    },
    [deleteVisitNoteStore]: (state, action) => {
      const { noteId, clientId } = action.payload;
      const notes = state.data[clientId].visits;
      deleteNote(notes, noteId, state.data[clientId]);
    },
    [deleteApplicationNoteStore]: (state, action) => {
      const { noteId, clientId } = action.payload;
      const notes = state.data[clientId].applicationNotes;
      deleteNote(notes, noteId, state.data[clientId]);
    },
    [deleteCallNoteStore]: (state, action) => {
      const { noteId, clientId } = action.payload;
      const notes = state.data[clientId].phoneCalls;
      deleteNote(notes, noteId, state.data[clientId]);
    },
    [deleteRemarkNoteStore]: (state, action) => {
      const { noteId, clientId } = action.payload;
      const notes = state.data[clientId].remarks;
      deleteNote(notes, noteId, state.data[clientId]);
    },
    [updateNoteStore]: (state, action) => {
      const { noteId, values } = action.payload;
      const { clientId } = values;
      const notes = state.data[clientId].visits;
      updateNote(notes, noteId, values);
    },
    [updateCallNoteStore]: (state, action) => {
      const { noteId, values } = action.payload;
      const { clientId } = values;
      const notes = state.data[clientId].phoneCalls;
      updateNote(notes, noteId, values);
    },
    [updateRemarkNoteStore]: (state, action) => {
      const { noteId, values } = action.payload;
      const { clientId } = values;
      const notes = state.data[clientId].remarks;
      updateNote(notes, noteId, values);
    },
    [updateApplicationNoteStore]: (state, action) => {
      const { noteId, values } = action.payload;
      const { clientId } = values;
      const notes = state.data[clientId].applicationNotes;
      const index = notes.data.findIndex((note) => note.id === noteId);
      if (index !== -1) {
        notes.data[index] = {
          id: noteId,
          ...values,
          edited: true,
        };

        markApplicationEdited(notes.data[index], state.data[clientId]);
      }
    },
    [attachApplicationNote]: (state, action) => {
      const { noteId, applicationId, clientId } = action.payload;
      updateApplicationSection(
        state.data[clientId],
        applicationId,
        noteId,
        APPLICATION_SECTION_NAMES.NOTES
      );
    },
    [deleteVisitById]: (state, action) => {
      const { visitId, clientId } = action.payload;
      state.data[clientId].visits.data.findIndex((visit) => {
        if (visit.nextAction) {
          const actionIndex = state.data[clientId].openActions.data.findIndex(
            (action) => visit.nextAction.id === action.id
          );
          if (actionIndex !== -1) {
            state.data[clientId].openActions.data.splice(actionIndex, 1);
            state.data[clientId].openActions.total =
              state.data[clientId].openActions.data.length;
          }
        }
        return visit.id === visitId;
      });
      state.data[clientId].visits.data = state.data[
        clientId
      ].visits.data.filter(({ id }) => id !== visitId);
      state.data[clientId].visits.total =
        state.data[clientId].visits.data.length;
    },
    [setVisitNoteContactPerson]: (state, action) => {
      const { contactPersonId, noteId, clientId } = action.payload;
      const notes = state.data[clientId].visits;
      const note = notes.data.find((note) => note.id === noteId);
      if (note) {
        note.contactPersonId = contactPersonId;
        note.edited = true;
      }
    },
    [setCallNoteContactPerson]: (state, action) => {
      const { contactPersonId, noteId, clientId } = action.payload;
      const notes = state.data[clientId].phoneCalls;
      const note = notes.data.find((note) => note.id === noteId);
      if (note) {
        note.contactPersonId = contactPersonId;
        note.edited = true;
      }
    },
    [setRemarkNoteContactPerson]: (state, action) => {
      const { contactPersonId, noteId, clientId } = action.payload;
      const notes = state.data[clientId].remarks;
      const note = notes.data.find((note) => note.id === noteId);
      if (note) {
        note.contactPersonId = contactPersonId;
        note.edited = true;
      }
    },
    [setApplicationContactPerson]: (state, action) => {
      const { contactPersonId, applicationId, clientId } = action.payload;
      updateApplicationSection(
        state.data[clientId],
        applicationId,
        contactPersonId,
        APPLICATION_SECTION_NAMES.CONTACTS
      );
    },
    [setApplicationNoteContactPerson]: (state, action) => {
      const { contactPersonId, clientId, noteId } = action.payload;
      const notes = state.data[clientId].applicationNotes;
      const note = notes.data.find((note) => note.id === noteId);
      if (note) {
        note.contactPersonId = contactPersonId;
        note.edited = true;
      }
    },
    [deleteApplicationContactPerson]: (state, action) => {
      const { contactPersonId, applicationId, clientId } = action.payload;
      const application = state.data[clientId].applications.data.find(
        (application) => application.id === applicationId
      );
      if (application) {
        application.contacts = application.contacts.filter((id) => {
          return id !== contactPersonId;
        });
        application.edited = true;
        application.firstCreation = false;

        const notes = state.data[clientId].applicationNotes.data.filter(
          (note) => note.applicationId === applicationId
        );
        notes.forEach((note) => {
          if (note.contactPersonId === contactPersonId) {
            note.contactPersonId = '';
          }
        });
      }
    },
    [addNextActionToVisitNote]: (state, action) => {
      const { noteId, clientId, nextAction, notMarkNoteAsEdited } =
        action.payload;
      const notes = state.data[clientId].visits;
      addNextActionToNote(notes, noteId, nextAction, notMarkNoteAsEdited);
    },
    [addNextActionToCallNote]: (state, action) => {
      const { noteId, clientId, nextAction, notMarkNoteAsEdited } =
        action.payload;
      const notes = state.data[clientId].phoneCalls;

      addNextActionToNote(notes, noteId, nextAction, notMarkNoteAsEdited);
    },
    [addNextActionToRemarkNote]: (state, action) => {
      const { noteId, clientId, nextAction, notMarkNoteAsEdited } =
        action.payload;
      const notes = state.data[clientId].remarks;

      addNextActionToNote(notes, noteId, nextAction, notMarkNoteAsEdited);
    },
    [addNextActionToApplicationNote]: (state, action) => {
      const { noteId, clientId, nextAction, notMarkNoteAsEdited } =
        action.payload;
      const notes = state.data[clientId].applicationNotes;

      const note = notes.data.find(
        (note) => note.id === noteId || note.id === nextAction.id
      );

      if (note) {
        note.nextAction = nextAction;
        if (!notMarkNoteAsEdited) {
          note.edited = true;
        }

        const application = state.data[clientId].applications.data.find(
          (item) => item.id === note.applicationId
        );
        if (application) {
          if (!notMarkNoteAsEdited) {
            note.edited = true;
          }
        }
      }
    },
    [addProductToVisit]: (state, action) => {
      const { noteId, clientId, demoedProduct } = action.payload;
      const notes = state.data[clientId].visits;
      const foundNote = notes.data.find((note) => note.id === noteId);
      const noteDate = foundNote.visitDate;

      const productAlreadyDemoed = state.data[clientId].demoProducts.data.find(
        (product) => product.id === demoedProduct.id
      );

      if (productAlreadyDemoed) {
        if (
          isAfter(
            new Date(productAlreadyDemoed.presentedAt),
            new Date(noteDate)
          )
        ) {
          return;
        }

        productAlreadyDemoed.presentedAt = noteDate;
        productAlreadyDemoed.reportId = noteId;
        productAlreadyDemoed.name = demoedProduct.name;
        productAlreadyDemoed.reportType = REPORT_TYPES.VISIT;
      } else {
        state.data[clientId].demoProducts.data.push({
          ...demoedProduct,
          presentedAt: noteDate,
          reportId: noteId,
          reportType: REPORT_TYPES.VISIT,
        });
        state.data[clientId].demoProducts.total =
          state.data[clientId].demoProducts.data.length;
      }
    },
    [addProductToApplicationNote]: (state, action) => {
      const { noteId, clientId, demoedProduct } = action.payload;
      const notes = state.data[clientId].applicationNotes;
      const foundNote = notes.data.find((note) => note.id === noteId);
      const { noteDate } = foundNote;

      const productAlreadyDemoed = state.data[clientId].demoProducts.data.find(
        (product) => product.id === demoedProduct.id
      );

      if (productAlreadyDemoed) {
        if (
          isAfter(
            new Date(productAlreadyDemoed.presentedAt),
            new Date(foundNote)
          )
        ) {
          return;
        }

        productAlreadyDemoed.presentedAt = noteDate;
        productAlreadyDemoed.reportId = noteId;
        productAlreadyDemoed.reportType = REPORT_TYPES.APPLICATION_NOTE;
      } else {
        state.data[clientId].demoProducts.data.push({
          ...demoedProduct,
          presentedAt: noteDate,
          reportId: noteId,
          reportType: REPORT_TYPES.APPLICATION_NOTE,
        });
        state.data[clientId].demoProducts.total =
          state.data[clientId].demoProducts.data.length;
      }
    },
    [removeDemoedProduct]: (state, action) => {
      const { noteId, clientId, demoedProduct } = action.payload;

      const { visits } = state.data[clientId];

      const otherVisitWithThisDemoedProduct = visits.data.find(
        (visit) =>
          visit.id !== noteId &&
          visit.demoProducts.some(({ code }) => code === demoedProduct.id)
      );

      if (otherVisitWithThisDemoedProduct) {
        const foundDemoProduct = state.data[clientId].demoProducts.data.find(
          ({ id }) => id === demoedProduct.id
        );
        foundDemoProduct.presentedAt =
          otherVisitWithThisDemoedProduct.visitDate;
        foundDemoProduct.reportId = otherVisitWithThisDemoedProduct.id;
        foundDemoProduct.reportType = REPORT_TYPES.VISIT;
      } else {
        state.data[clientId].demoProducts.data = state.data[
          clientId
        ].demoProducts.data.filter(
          (product) => product.id !== demoedProduct.id
        );
        state.data[clientId].demoProducts.total =
          state.data[clientId].demoProducts.data.length;
      }
    },
    [removeDemoedProductFromApplicationNote]: (state, action) => {
      const { noteId, clientId, demoedProduct } = action.payload;

      const { applicationNotes } = state.data[clientId];

      const otherNoteWithThisDemoedProduct = applicationNotes.data.find(
        (note) =>
          note.id !== noteId &&
          note.demoProducts.some(({ code }) => code === demoedProduct.id)
      );

      if (otherNoteWithThisDemoedProduct) {
        const foundDemoProduct = state.data[clientId].demoProducts.data.find(
          ({ id }) => id === demoedProduct.id
        );
        foundDemoProduct.presentedAt = otherNoteWithThisDemoedProduct.noteDate;
        foundDemoProduct.reportId = otherNoteWithThisDemoedProduct.id;
        foundDemoProduct.reportType = REPORT_TYPES.APPLICATION_NOTE;
      } else {
        state.data[clientId].demoProducts.data = state.data[
          clientId
        ].demoProducts.data.filter(
          (product) => product.id !== demoedProduct.id
        );
        state.data[clientId].demoProducts.total =
          state.data[clientId].demoProducts.data.length;
      }
    },
    [addDemoedProductsPerNote]: (state, action) => {
      const { noteId, clientId, demoedProducts } = action.payload;
      const notes = state.data[clientId].visits;
      addDemoProductsToNote(notes, noteId, demoedProducts);
    },
    [addDemoedProductsPerApplicationNote]: (state, action) => {
      const { noteId, clientId, demoedProducts } = action.payload;
      const notes = state.data[clientId].applicationNotes;
      addDemoProductsToNote(notes, noteId, demoedProducts);
    },
    [addProductsPerCallNote]: (state, action) => {
      const { noteId, clientId, suggestedProducts } = action.payload;
      const notes = state.data[clientId].phoneCalls;

      const note = notes.data.find((note) => note.id === noteId);
      if (note) {
        note.suggestedProducts = suggestedProducts.map((product) => {
          return {
            code: product.code,
          };
        });
        note.edited = true;
      }
    },
    [addNextActionToOpenActions]: (state, action) => {
      const { clientId, nextAction } = action.payload;
      state.data[clientId].openActions.data.push(nextAction);
      state.data[clientId].openActions.total =
        state.data[clientId].openActions.data.length;
    },
    [updateNextActionInOpenActions]: (state, action) => {
      const { clientId, nextAction } = action.payload;
      const matchingOpenAction = state.data[clientId].openActions.data.find(
        (item) => item.id === nextAction.id
      );
      if (!matchingOpenAction) {
        state.data[clientId].openActions.data.unshift(nextAction);
      } else {
        state.data[clientId].openActions.data = state.data[
          clientId
        ].openActions.data.map((action) =>
          action.id === matchingOpenAction.id ? nextAction : action
        );
      }
      state.data[clientId].openActions.total =
        state.data[clientId].openActions.data.length;
    },
    [deleteNextActionFromVisitNote]: (state, action) => {
      const { noteId, clientId } = action.payload;

      const notes = state.data[clientId].visits;
      const note = notes.data.find((note) => note.id === noteId);

      if (noteId) {
        note.nextAction = null;
        note.edited = true;
      }
    },
    [deleteNextActionFromCallNote]: (state, action) => {
      const { noteId, clientId } = action.payload;

      const notes = state.data[clientId].phoneCalls;
      const note = notes.data.find((note) => note.id === noteId);

      if (noteId) {
        note.nextAction = null;
        note.edited = true;
      }
    },
    [deleteNextActionFromRemarkNote]: (state, action) => {
      const { noteId, clientId } = action.payload;

      const notes = state.data[clientId].remarks;
      const note = notes.data.find((note) => note.id === noteId);

      if (noteId) {
        note.nextAction = null;
        note.edited = true;
      }
    },
    [deleteNextActionFromApplicationNote]: (state, action) => {
      const { noteId, clientId } = action.payload;

      const notes = state.data[clientId].applicationNotes;
      const note = notes.data.find((note) => note.id === noteId);

      if (noteId) {
        note.nextAction = null;
        note.edited = true;
      }
    },
    [deleteNextActionFromOpenActions]: (state, action) => {
      const { clientId, actionId } = action.payload;
      const matchingOpenActionIdx = state.data[
        clientId
      ].openActions.data.findIndex((item) => item.id === actionId);
      if (matchingOpenActionIdx !== -1) {
        state.data[clientId].openActions.data.splice(matchingOpenActionIdx, 1);
      }
      state.data[clientId].openActions.total =
        state.data[clientId].openActions.data.length;
    },
    [fetchClientDataById.pending]: (state, action) => {
      const { clientId } = action.meta.arg;
      state.loadingStatus[clientId] = 'fetching';
    },
    [fetchClientDataById.fulfilled]: (state, action) => {
      const data = action.payload;
      const { clientId } = action.meta.arg;
      const parseAttachments = (visitsData) => {
        return visitsData.map((visit) => {
          const { attachments, ...rest } = visit;
          const parsedAttachments =
            attachments?.map((id) => ({ id, description: undefined })) || [];
          return { ...rest, attachments: parsedAttachments };
        });
      };
      const visitsBeingEdited =
        state.data[data.id]?.visits?.data?.filter(
          ({ edited }) => typeof edited !== 'undefined'
        ) || [];
      const demoProductsForVisitsBeingEdited =
        state.data[data.id]?.demoProducts?.data?.filter(
          ({ reportId }) => reportId === visitsBeingEdited[0]?.id
        ) || [];
      state.data[data.id] = {
        ...action.payload,
        visits: {
          total: [...action.payload.visits.data, ...visitsBeingEdited].length,
          data: [
            ...parseAttachments(action.payload.visits.data),
            ...parseAttachments(visitsBeingEdited),
          ],
        },
        demoProducts: {
          total: [
            ...action.payload.demoProducts.data,
            ...demoProductsForVisitsBeingEdited,
          ].length,
          data: [
            ...action.payload.demoProducts.data,
            ...demoProductsForVisitsBeingEdited,
          ],
        },
      };
      if (clientId !== data.id) {
        state.data[clientId] = {
          ...state.data[data.id],
          id: clientId,
        };
      }
      state.loadingStatus[data.id] = 'fetched';
    },
    [fetchClientDataById.rejected]: (state, action) => {
      const { clientId } = action.meta.arg;
      state.loadingStatus[clientId] = 'not-fetched';
    },
    [createNewApplication]: (state, action) => {
      const { applicationId, clientId, defaultName, isSharingAllowed } =
        action.payload;
      state.data[clientId].applications.data.unshift({
        id: applicationId,
        clientId,
        createdAt: formatDate(new Date()),
        updatedAt: formatDate(new Date()),
        name: defaultName,
        costSavings: '',
        uptimeGain: '',
        laborTimeSaved: '',
        note: '',
        contacts: [],
        photos: [], // all gallery items (photos and videos)
        isDraft: true,
        technical: {
          bearing: {},
          chain: {},
          gearbox: {},
          other: {},
          environment: [{ parameter: '', value: '' }],
        },
        before: {
          previousLubricants: [''],
        },
        after: {},
        edited: false,
        createdLocally: true,
        firstCreation: true,
        isConfidential: !isSharingAllowed,
      });
      state.data[clientId].applications.total =
        state.data[clientId].applications.data.length;
    },
    [updateApplication]: (state, action) => {
      const { applicationId, clientId, values } = action.payload;
      const index = state.data[clientId].applications.data.findIndex(
        ({ id }) => id === applicationId
      );
      const oldState = state.data[clientId].applications.data[index];

      state.data[clientId].applications.data[index] = {
        ...oldState,
        id: applicationId,
        clientId,
        edited:
          oldState.name !== '' && values.name === '' && oldState.firstCreation
            ? oldState.edited
            : true,
        firstCreation:
          oldState.name !== '' && values.name === '' && oldState.firstCreation
            ? oldState.firstCreation
            : false,
        ...values,
      };
    },
    [deleteApplicationLocally]: (state, action) => {
      const { applicationId, clientId } = action.payload;
      const index = state.data[clientId].applications.data.findIndex(
        ({ id }) => id === applicationId
      );
      const application = state.data[clientId].applications.data[index];
      if (application) {
        state.data[clientId].applications.data.splice(index, 1);
        state.data[clientId].applications.total =
          state.data[clientId].applications.data.length;

        removeOpenActionByApplication(application, state.data[clientId]);
      }
    },
    [updateApplicationsSection]: (state, action) => {
      const { applicationId, clientId, values, section } = action.payload;
      const index = state.data[clientId].applications.data.findIndex(
        ({ id }) => id === applicationId
      );

      const foundApplication = state.data[clientId].applications.data[index];

      const oldSectionState = foundApplication[section];

      state.data[clientId].applications.data[index][section] = {
        ...oldSectionState,
        ...values,
      };

      foundApplication.edited = true;
      foundApplication.firstCreation = false;
    },
    [setApplicationInterflonLubricant]: (state, action) => {
      const { applicationId, clientId, lubricant } = action.payload;
      const index = state.data[clientId].applications.data.findIndex(
        ({ id }) => id === applicationId
      );

      const foundApplication = state.data[clientId].applications.data[index];

      foundApplication.after.lubricant = lubricant;
      foundApplication.edited = true;
      foundApplication.firstCreation = false;
    },
    [addPhoto]: (state, action) => {
      const { clientId, applicationId, photo } = action.payload;
      addGalleryItemToApplication(state, clientId, applicationId, photo);
    },
    [addAttachmentImage]: (state, action) => {
      const { clientId, reportId, photo, callSource } = action.payload;
      if (callSource === 'editReport') {
        state.data[clientId].visits.data
          .find(({ id }) => id === reportId)
          ?.attachments?.push(photo);
      }
    },
    [updateFileDescriptionStore]: (state, action) => {
      const { clientId, reportId, fileId, description } = action.payload;
      const file = state.data[clientId].visits.data
        .find(({ id }) => id === reportId)
        .attachments.find((attachment) => attachment.id === fileId);
      if (file) {
        file.description = description;
      }
    },
    [addVideo]: (state, action) => {
      const { clientId, applicationId, data } = action.payload;
      addGalleryItemToApplication(state, clientId, applicationId, data);
    },
    [addAttachmentVideo]: (state, action) => {
      const { clientId, reportId, data, callSource } = action.payload;
      if (callSource === 'editReport') {
        state.data[clientId].visits.data
          .find(({ id }) => id === reportId)
          ?.attachments?.push(data);
      }
    },
    [deleteAttachmentByIdStore]: (state, action) => {
      const { clientId, reportId, attachmentId } = action.payload;
      const visit = state.data[clientId].visits.data.find(
        (visit) => visit.id === reportId
      );
      if (visit?.attachments) {
        visit.attachments = visit.attachments.filter(
          (attachment) => attachment.id !== attachmentId
        );
      }
    },
    [cancelVisitById]: (state, action) => {
      const { clientId, reportId, reportData, callSource } = action.payload;
      if (callSource === 'editReport') {
        if (reportData.attachments?.length) {
          const visit = state.data[clientId].visits.data.find(
            (visit) => visit.id === reportId
          );
          visit.attachments = visit.attachments.filter(
            (attachment) => !reportData.attachments.includes(attachment.id)
          );
        }
      }
    },
    [ADD_VIDEO_ROLLBACK]: (state, action) => {
      const { clientId, applicationId, data } = action.meta;
      const applicationIndex = state.data[clientId].applications.data.findIndex(
        ({ id }) => id === applicationId
      );

      const application =
        state.data[clientId].applications.data[applicationIndex];

      if (application && application.photos.length) {
        application.photos = application.photos.filter((id) => id !== data.id);
      }
    },
    [ACTION_ADD_PHOTO_COMMIT]: (state, action) => {
      const photoUrl = action.payload.fileUrl;
      const { clientId } = action.meta;
      const currentPhoto = action.meta.photo;
      const updatedPhoto = {
        ...currentPhoto,
        createdAt: formatDate(new Date()),
        updatedAt: formatDate(new Date()),
        fileUrl: photoUrl,
      };

      state.data[clientId].photos.data.unshift(updatedPhoto);
      state.data[clientId].photos.total =
        state.data[clientId].photos.data.length;
    },
    [editPhoto]: (state, action) => {
      const { clientId, photoId, photo } = action.payload;
      if (!state.data[clientId]) {
        return;
      }

      editGalleryItem(state, clientId, photoId, photo);
    },
    [editVideo]: (state, action) => {
      const { clientId, videoId, video } = action.payload;
      if (!state.data[clientId]) {
        return;
      }

      editGalleryItem(state, clientId, videoId, video);
    },
    [deletePhoto]: (state, action) => {
      const { clientId, photoId, applicationId } = action.payload;
      if (!state.data[clientId]) {
        return;
      }

      removeGalleryItem(state, clientId, photoId);

      if (applicationId) {
        removeGalleryItemFromApplication(
          state,
          clientId,
          applicationId,
          photoId
        );
      }
    },
    [deleteVideo]: (state, action) => {
      const { clientId, videoId, applicationId } = action.payload;
      if (!state.data[clientId]) {
        return;
      }

      removeGalleryItem(state, clientId, videoId);

      if (applicationId) {
        removeGalleryItemFromApplication(
          state,
          clientId,
          applicationId,
          videoId
        );
      }
    },
    [updateClientName]: (state, action) => {
      const { clientId, name } = action.payload;
      state.data[clientId].name = name;
    },
    [deleteClientData]: (state, action) => {
      const { clientId } = action.payload;
      delete state.data[clientId];
      delete state.loadingStatus[clientId];
    },
    [updateDemoedProductsDate]: (state, action) => {
      const { date, noteId, clientId, demoedProductId } = action.payload;

      const foundDemoProduct = state.data[clientId].demoProducts.data.find(
        (product) => product.id === demoedProductId
      );

      if (isAfter(new Date(date), new Date(foundDemoProduct.presentedAt))) {
        foundDemoProduct.presentedAt = date;
        foundDemoProduct.reportId = noteId;
      } else if (
        !isAfter(new Date(date), new Date(foundDemoProduct.presentedAt)) &&
        foundDemoProduct.reportId === noteId
      ) {
        foundDemoProduct.presentedAt = date;
      }
    },
    [applicationNoteEditionDone]: (state, action) => {
      const { noteId, clientId } = action.payload;
      const applicationNote = state.data[clientId].applicationNotes.data.find(
        (note) => note.id === noteId
      );

      if (applicationNote) {
        applicationNote.edited = undefined;
        applicationNote.attached = true;
      }
    },
    [updateQuotationHistory]: (state, action) => {
      const { clientId, values } = action.payload;
      state.data[clientId].orderHistory.quotations.push(values);
    },
    'quotation/convert/post': (state, action) => {
      const { clientId, quotationId } = action.meta;
      state.data[clientId].orderHistory.quotations = state.data[
        clientId
      ].orderHistory.quotations.filter(({ id }) => id !== quotationId);
    },
    'visit/post/commit': (state, action) => {
      const { clientId, visitId } = action.meta;
      const visitIdx = state.data[clientId].visits.data.findIndex(
        ({ id }) => id === visitId
      );
      if (visitIdx !== -1) {
        delete state.data[clientId].visits.data[visitIdx].edited;
      }
    },
    'visit/put/commit': (state, action) => {
      const { clientId, visitId } = action.meta;
      const visitIdx = state.data[clientId].visits.data.findIndex(
        ({ id }) => id === visitId
      );
      if (visitIdx !== -1) {
        delete state.data[clientId].visits.data[visitIdx].edited;
      }
    },
  },
});

export const { clearPhotoInApplication } = clientDataSlice.actions;

export default clientDataSlice.reducer;
