import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { validate } from 'uuid';
import { isToday } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { AsideButton } from 'frontend-components';
import { scrollToError } from 'frontend-components/lib/helpers';

import AppBar from '../../components/AppBar/AppBar.connected';
import DeleteDialog from '../../components/Dialog/DeleteDialog/DeleteDialog';
import Tabs from '../../components/Tabs/Tabs';
import OverviewTab from './OverviewTab';
import TechnicalTab from './TechnicalTab/TechnicalTab';
import BeforeTab from './BeforeTab';
import AfterTab from './AfterTab';
import { parseAppReportBeforePost } from '../../helpers/parseAppReport';
import { TAG_PROP_TYPES } from '../../constants/applicationScreen';
import {
  updateApplicationExpandedPanel,
  clearApplicationExpandedPanel,
} from '../../store/reducers/clientUI';
import { NO_EXPANDED_PANEL } from '../../constants/lists';
import {
  applicationDataShape,
  applicationNotesShape,
  contactPersonDataShape,
  photosDataShape,
  clientListItemShape,
} from '../../helpers/clientDataPropTypes';
import { deleteClientFromPlannedClients } from '../../store/reducers/clients';

const ApplicationView = ({
  clientListItemData,
  applicationData,
  contactPersonsData,
  galleryItemsData,
  postAppReport,
  putAppReport,
  deleteApp,
  deleteAppLocally,
  createNewApp,
  updateApp,
  deleteAppContactPerson,
  beforeTag,
  afterTag,
  updateAppSection,
  overviewTag,
  addDisabled,
  editDisabled,
  applicationNotes,
  getApplicationNumber,
  currency,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { clientId, applicationId } = useParams();
  const [isDeleted, setIsDeleted] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [applicationNumber, setApplicationNumber] = useState(null);
  const [showDeleteApplicationDialog, setShowDeleteApplicationDialog] =
    useState(false);
  const [isAppNameMissing, setIsAppNameMissing] = useState(false);
  const hasLocalId = validate(applicationId);
  const { expandedPanel } = useSelector((state) => {
    return {
      expandedPanel: state.clientUI.applicationExpandedPanel[clientId]
        ? state.clientUI.applicationExpandedPanel[clientId]
        : NO_EXPANDED_PANEL,
    };
  });
  const { online } = useSelector((state) => state.offline);
  const { isFormBlocked } = useSelector((state) => state.clientUI);
  const { currentApplication } = useSelector((state) => state.drafts);

  useEffect(() => {
    if (isDeleted) return;
    if (!applicationData && currentApplication?.uuId !== applicationId) {
      const appNumber = getApplicationNumber();
      const defaultName = `${t('application_name_ph')}.${appNumber}`;
      setApplicationNumber(appNumber);
      createNewApp(
        applicationId,
        clientId,
        defaultName,
        clientListItemData.shareAllowed ??
          clientListItemData.isSharingAllowed ??
          true
      );
    }
  }, [
    applicationData,
    applicationId,
    currentApplication?.uuId,
    clientId,
    isDeleted,
    createNewApp,
    getApplicationNumber,
    t,
    clientListItemData.shareAllowed,
    clientListItemData.isSharingAllowed,
  ]);

  useEffect(() => {
    if (location.state && location.state.from === 'addInterflonProduct') {
      setSelectedTab(3);
      history.replace(location.pathname, {
        from: '',
      });
    }
  }, [history, location.pathname, location.state]);

  const saveApplication = () => {
    const { createdLocally } = applicationData;
    const dataToSend = parseAppReportBeforePost(cloneDeep(applicationData));
    createdLocally && validate(applicationId)
      ? postAppReport(applicationId, dataToSend, clientId)
      : putAppReport(applicationId, dataToSend, clientId);
  };

  useEffect(() => {
    if (!applicationData) return;
    const { edited, name } = applicationData;
    online && edited && name && saveApplication();

    if (!location.state || location.state.from !== 'addInterflonProduct') {
      setSelectedTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online]);

  const deleteClientFromPlanned = () => {
    const notesData = applicationNotes?.data?.filter(({ id }) =>
      applicationData.notes.includes(id)
    );
    notesData.forEach((note) => {
      if (note.nextAction?.doAt && isToday(new Date(note.nextAction.doAt))) {
        dispatch(deleteClientFromPlannedClients(clientId));
      }
    });
  };

  const handleDeleteApplication = () => {
    setIsDeleted(true);
    deleteApp(applicationId, clientId);
    deleteAppLocally(applicationId, clientId);
    if (!online) {
      deleteClientFromPlanned();
    }
    history.push(`/customers/${clientId}`);
  };

  const backButtonAction = () => {
    if (isFormBlocked) {
      scrollToError();
      return;
    }
    const { edited, name } = applicationData;
    if (edited && !name.trim()) {
      setIsAppNameMissing(true);
      setSelectedTab(0);
      return;
    }
    edited && saveApplication();
    history.push(`/customers/${clientId}`);
    dispatch(clearApplicationExpandedPanel({ clientId }));
  };

  useEffect(() => {
    return () => {
      if (
        history.location.pathname === `/customers/${clientId}` &&
        !applicationData.edited &&
        applicationData.firstCreation
      ) {
        deleteAppLocally(applicationId, clientId);
      }
    };
  }, [
    history.location,
    applicationData,
    clientId,
    applicationId,
    deleteAppLocally,
  ]);

  const [displayBinIcon, setDisplayBinIcon] = useState(false);
  useEffect(() => {
    if (online) {
      setDisplayBinIcon(
        !hasLocalId && applicationData && !applicationData.firstCreation
      );
    } else {
      setDisplayBinIcon(applicationData && !applicationData.firstCreation);
    }
  }, [applicationData, hasLocalId, online]);

  const isNew = applicationData?.createdLocally;
  const disabled = isNew ? addDisabled : editDisabled;

  return (
    <>
      <Container maxWidth="sm">
        <AppBar
          title={t('app_title')}
          displayBackButton
          backButtonAction={backButtonAction}
          aside={
            displayBinIcon && (
              <AsideButton
                onClick={() => {
                  setShowDeleteApplicationDialog(true);
                }}
                disabled={editDisabled}
              >
                <DeleteIcon />
              </AsideButton>
            )
          }
        />
      </Container>
      {applicationData && (
        <Tabs
          tabLabels={[
            t('app_tab_overview'),
            t('app_tab_technical'),
            t('app_tab_before'),
            t('app_tab_after'),
          ]}
          scrollable
          fixedTop
          selectedTab={selectedTab}
          blockTabs={isFormBlocked}
        >
          <OverviewTab
            expandedPanel={expandedPanel}
            setExpandedPanel={(expandedPanel) => {
              dispatch(
                updateApplicationExpandedPanel({
                  clientId,
                  expandedPanel,
                })
              );
            }}
            isAppNameMissing={isAppNameMissing}
            setIsAppNameMissing={setIsAppNameMissing}
            updateApp={updateApp}
            deleteAppContactPerson={deleteAppContactPerson}
            beforeTag={beforeTag}
            afterTag={afterTag}
            applicationData={applicationData}
            contactPersonsData={contactPersonsData}
            galleryItemsData={galleryItemsData}
            overviewTag={overviewTag}
            disabled={disabled}
            applicationNotes={applicationNotes}
            currency={currency}
            applicationNumber={applicationNumber}
          />
          <TechnicalTab
            applicationData={applicationData}
            updateAppSection={updateAppSection}
            disabled={disabled}
          />
          <BeforeTab
            applicationData={applicationData}
            updateAppSection={updateAppSection}
            disabled={disabled}
            currency={currency}
          />
          <AfterTab
            applicationData={applicationData}
            updateAppSection={updateAppSection}
            disabled={disabled}
            currency={currency}
          />
        </Tabs>
      )}
      <DeleteDialog
        title={t('app_delete_title')}
        open={showDeleteApplicationDialog}
        setOpen={setShowDeleteApplicationDialog}
        handleDelete={handleDeleteApplication}
      />
    </>
  );
};

ApplicationView.propTypes = {
  clientListItemData: clientListItemShape,
  applicationData: applicationDataShape,
  contactPersonsData: contactPersonDataShape,
  galleryItemsData: photosDataShape,
  postAppReport: PropTypes.func,
  putAppReport: PropTypes.func,
  deleteApp: PropTypes.func,
  deleteAppLocally: PropTypes.func,
  createNewApp: PropTypes.func,
  updateApp: PropTypes.func,
  deleteAppContactPerson: PropTypes.func,
  beforeTag: PropTypes.shape(TAG_PROP_TYPES),
  afterTag: PropTypes.shape(TAG_PROP_TYPES),
  overviewTag: PropTypes.shape(TAG_PROP_TYPES),
  updateAppSection: PropTypes.func,
  addDisabled: PropTypes.bool,
  editDisabled: PropTypes.bool,
  applicationNotes: applicationNotesShape,
  getApplicationNumber: PropTypes.func,
  currency: PropTypes.string,
};

export default ApplicationView;
