import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'frontend-components';

import ClientDetails from './ClientDetails';
import { ButtonContainer } from '../../components/FixedFooter/FixedFooter';
import {
  updateNewClientDetails,
  updateNewClientName,
} from '../../store/reducers/client';
import { createClientStore } from '../../store/actions/clientDetailsActions';
import { addClientToSearch, postClient } from '../../store/reducers/clients';
import DeleteDialog from '../../components/Dialog/DeleteDialog/DeleteDialog';
import {
  prepareClientDetailsPayload,
  clientNameValid,
} from '../../helpers/clients';
import { addProductsStore } from '../../store/reducers/products';
import { scrollToError } from '../../helpers/scrollToError';

const NewClientContainer = styled.div`
  padding-bottom: 96px;

  .MuiButton-root.Mui-disabled:not(.MuiSwitch-switchBase):not(.MuiCheckbox-root) {
    display: flex;
  }
`;

const NewClientView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { clientId } = useParams();
  const { t } = useTranslation();
  const [isFormCorrect, setIsFormCorrect] = useState(false);
  const [showConfirmLeaveDialog, setShowConfirmLeaveDialog] = useState(false);
  const [validate, setValidate] = useState(false);

  const handleBackAction = () => {
    history.push(`/customers/`);
  };

  const { newClientListItem, newClientData } = useSelector(
    (state) => state.client
  );

  const { defaultProducts } = useSelector((state) =>
    state.user.data.defaultProducts ? state.user.data : { defaultProducts: [] }
  );

  const navigateToGallery = () => {
    history.push(`/customers/${clientId}/client/create/gallery`);
  };

  const backButtonAction = (clientListItemData) => {
    if (!clientListItemData.edited) {
      history.push(`/customers/`);
    }
    if (clientListItemData.edited) {
      setShowConfirmLeaveDialog(true);
    }
  };

  useEffect(() => {
    if (
      newClientData.name &&
      clientNameValid(newClientData.name) &&
      newClientListItem.sicCode
    ) {
      setIsFormCorrect(true);
    } else {
      setIsFormCorrect(false);
    }
  }, [newClientData, newClientListItem]);

  return (
    <NewClientContainer>
      <ClientDetails
        clientData={newClientData}
        clientListItemData={newClientListItem}
        updateClientDetails={updateNewClientDetails}
        updateClientName={updateNewClientName}
        editAddressPath={`/customers/${clientId}/client/create/address`}
        editSicCodePath={`/customers/${clientId}/client/create/sic`}
        isNew
        title={t('create_client_title')}
        navigateToGallery={navigateToGallery}
        backButtonAction={backButtonAction}
        disableGallery
        setValidate={setValidate}
        validate={validate}
        extraFooterComponent={
          <ButtonContainer padding="0 0 24px 0">
            <Button
              aria-label="create_client"
              theme="primary"
              onClick={() => {
                setValidate(true);
                if (!isFormCorrect) {
                  scrollToError();
                  return;
                }
                dispatch(
                  postClient(
                    clientId,
                    prepareClientDetailsPayload(newClientListItem)
                  )
                );
                dispatch(
                  addProductsStore({ clientId, products: [...defaultProducts] })
                );
                dispatch(createClientStore(clientId, newClientData));
                dispatch(addClientToSearch(newClientListItem));
                history.push(`/customers/${clientId}`);
              }}
            >
              {t('create_client_button')}
            </Button>
          </ButtonContainer>
        }
      />
      <DeleteDialog
        title={t('client_back_title')}
        message={t('client_back_message')}
        hideDefaultText
        yesText={t('client_back_positive')}
        noText={t('client_back_negative')}
        open={showConfirmLeaveDialog}
        setOpen={setShowConfirmLeaveDialog}
        handleDelete={handleBackAction}
      />
    </NewClientContainer>
  );
};

export default NewClientView;
