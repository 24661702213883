import styled from 'styled-components';
import { default as MuiCard } from '@material-ui/core/Card';
import PlaceIcon from '@material-ui/icons/Place';
import { default as MuiIconButton } from '@material-ui/core/IconButton';
import WebsiteIcon from '@material-ui/icons/Public';
import { Button } from 'frontend-components';

export const StyledForm = styled.form`
  position: relative;
  padding-bottom: 40px;
`;

export const AddButton = styled(MuiIconButton)`
  padding: 7px;
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #e52413;
`;

export const ContactDetailsContainer = styled.div`
  margin-bottom: 40px;

  textarea {
    display: block;
  }

  & .MuiFormControl-root {
    margin: 24px 0 16px;
  }

  [disabled] .MuiSvgIcon-root {
    opacity: 0.38;
  }
`;

export const AddressCard = styled(MuiCard)`
  margin-top: 28px;
  padding: 16px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #ffffff;
  width: 100%;
`;

export const ClassificationCodeContainer = styled.div`
  position: absolute;
  right: 0;
  top: 20px;
`;

export const AddressTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const AddressContent = styled.p`
  margin: 34px 0 21px 0;
  padding: 0 48px 0 0;
  position: relative;
`;

export const AddressText = styled.h2`
  flex: 1;
  padding: 0 0 0 8px;
  margin: 0;
  font-size: 14px;
`;

export const NavigateMeButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  text-transform: none;
  padding-right: 0;
  letter-spacing: normal;

  .MuiSvgIcon-root {
    color: #999999;
    margin-right: 8px;
    margin-left: -8px;
  }
`;

export const AddressPlaceIcon = styled(PlaceIcon)`
  color: #999999;
`;

export const EditAddressButton = styled(MuiIconButton)`
  position: absolute;
  right: -12px;
  top: -12px;

  .MuiSvgIcon-root {
    color: #999999;
  }
`;

export const EntrancePhotoContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  display: flex;
`;

export const EntrancePhotoContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #666666;

  img {
    cursor: pointer;
  }

  .MuiSvgIcon-root {
    vertical-align: middle;
    margin-right: 8px;
  }
`;

export const EntranceImg = styled.img`
  width: 81px;
  height: 61px;
`;

export const StyledWebsiteIcon = styled(WebsiteIcon)`
  color: #e42313;
`;
