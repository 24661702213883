import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import withClientData from '../../helpers/withClientData';
import NoteView from './NoteView';
import {
  createEmptyRemarkNote,
  updateRemarkNoteStore,
  deleteRemarkNoteStore,
} from '../../store/actions/notesActions';
import {
  deleteRemarkById,
  postRemark,
  putRemark,
} from '../../store/actions/remarkNotesActions';
import { prepareRemarkNotePayload } from '../../helpers/prepNotePayload';
import { NOTE_TYPES } from '../../constants/noteTypes';
import { updateExpandedPanel } from '../../store/reducers/clientUI';
import { LIST_TYPES } from '../../constants/lists';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const RemarkNoteView = ({ clientData }) => {
  const { noteId, clientId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const handlePostNote = (
    noteId,
    noteWithClientId,
    clientId,
    noteWithNextActionId
  ) => {
    dispatch(updateRemarkNoteStore(noteId, noteWithNextActionId));
    dispatch(postRemark(noteId, noteWithClientId, clientId));
    dispatch(
      updateExpandedPanel({ clientId, expandedPanel: LIST_TYPES.REPORTS })
    );
  };

  const handlePutNote = (
    noteId,
    noteWithClientId,
    clientId,
    noteWithNextActionId
  ) => {
    dispatch(updateRemarkNoteStore(noteId, noteWithNextActionId));
    dispatch(putRemark(noteId, noteWithClientId, clientId));
  };

  const handleDeleteNoteStore = (noteId, clientId) => {
    dispatch(deleteRemarkNoteStore(noteId, clientId));
  };

  const handleDeleteNote = (noteId, clientId) => {
    dispatch(deleteRemarkById(noteId, clientId));
  };

  const handleCreateEmptyNote = (noteId, clientId) => {
    dispatch(createEmptyRemarkNote(noteId, clientId));
  };

  const handleUpdateNote = (values) => {
    const noteWithClientId = {
      ...values,
      remarkDate: values.noteDate,
      clientId,
    };

    delete noteWithClientId.demoProducts;
    delete noteWithClientId.noteDate;

    dispatch(updateRemarkNoteStore(noteId, noteWithClientId));
  };

  const getNoteDate = (data) => data.remarkDate;

  const data =
    clientData.remarks &&
    clientData.remarks.data.find((remark) => remark.id === noteId);

  const gotoCreateView = (actionType) => {
    history.push(
      `/customers/${clientId}/${NOTE_TYPES.REMARKS}/${noteId}/${actionType}`
    );
  };

  return (
    <NoteView
      clientData={clientData}
      data={data}
      title={t('remark_note_title')}
      backURL={`/customers/${clientId}`}
      postNote={handlePostNote}
      putNote={handlePutNote}
      deleteNoteTitle={t('remark_note_delete_title')}
      deleteNoteStore={handleDeleteNoteStore}
      deleteNote={handleDeleteNote}
      createEmptyNote={handleCreateEmptyNote}
      updateNote={handleUpdateNote}
      datePickerTestId="remark_date_picker"
      getNoteDate={getNoteDate}
      prepareNotePayload={prepareRemarkNotePayload}
      gotoCreateView={gotoCreateView}
      hasDraftSwitch
      hideDraftToggle
    />
  );
};

RemarkNoteView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(RemarkNoteView);
