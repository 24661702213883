import unset from 'lodash/unset';

const FIELDS_TO_REMOVE_AFTER_GET = ['technical.componentParams'];

const FIELDS_TO_REMOVE_BEFORE_SEND = [
  'costSavings',
  'uptimeGain',
  'laborTimeSaved',
  'technical.bearing',
  'technical.chain',
  'technical.gearbox',
  'technical.other',
  'after.costSavings',
  'after.uptimeGain',
  'after.laborTimeSaved',
  'edited',
  'createdAt',
  'updatedAt',
  'id',
  'createdLocally',
  'firstCreation',
  'note',
];

const parseBoolToString = (fieldName, object, trueVal, falseVal) => {
  if (object[fieldName] !== null) {
    object[fieldName] = object[fieldName] ? trueVal : falseVal;
  }
};

const parseStringToBool = (fieldName, object, fieldValue) => {
  if (fieldName in object && object[fieldName] !== '') {
    object[fieldName] = object[fieldName] === fieldValue;
  }
};

const filterEmptyValues = (arr) => {
  if (arr.length < 1) return arr;
  return arr.filter((item) => item !== '');
};

export const parseAppReportAfterGet = (applications) => {
  applications.forEach((application) => {
    const { technical, before, after } = application;
    if (technical.componentType === 'gearbox') {
      const { componentParams } = technical;
      const fieldsToChange = [
        {
          fieldName: 'filter',
          object: componentParams,
          trueVal: 'yes',
          falseVal: 'no',
        },
        {
          fieldName: 'ventilationShaft',
          object: componentParams,
          trueVal: 'yes',
          falseVal: 'no',
        },
        {
          fieldName: 'leakages',
          object: componentParams,
          trueVal: 'yes',
          falseVal: 'no',
        },
      ];

      fieldsToChange.forEach(({ fieldName, object, trueVal, falseVal }) => {
        parseBoolToString(fieldName, object, trueVal, falseVal);
      });
    }

    const fieldsToChange = [
      {
        fieldName: 'manualApplication',
        object: before,
        trueVal: 'manual',
        falseVal: 'automatic',
      },
      {
        fieldName: 'machineRunning',
        object: before,
        trueVal: 'running',
        falseVal: 'stopped',
      },
      {
        fieldName: 'cleanBefore',
        object: before,
        trueVal: 'yes',
        falseVal: 'no',
      },
      {
        fieldName: 'manualApplication',
        object: after,
        trueVal: 'manual',
        falseVal: 'automatic',
      },
      {
        fieldName: 'machineRunning',
        object: after,
        trueVal: 'running',
        falseVal: 'stopped',
      },
      {
        fieldName: 'cleanBefore',
        object: after,
        trueVal: 'yes',
        falseVal: 'no',
      },
    ];

    fieldsToChange.forEach(({ fieldName, object, trueVal, falseVal }) => {
      parseBoolToString(fieldName, object, trueVal, falseVal);
    });

    if (application.componentType !== null) {
      technical[application.componentType] = technical.componentParams;
    }

    application.id = application.id.toString();

    FIELDS_TO_REMOVE_AFTER_GET.forEach((field) => unset(application, field));

    before.previousLubricants = filterEmptyValues(before.previousLubricants);
  });

  return applications;
};

export const parseAppReportBeforePost = (application) => {
  const { technical, before, after } = application;
  delete application.technical.machineBrand;
  delete application.technical.machineModel;
  delete application.technical.componentType;
  delete application.owned;
  delete application.ownerId;
  if (technical.componentType === 'gearbox') {
    const { gearbox } = technical;
    const fieldsToChange = [
      { fieldName: 'filter', object: gearbox, fieldValue: 'yes' },
      { fieldName: 'ventilationShaft', object: gearbox, fieldValue: 'yes' },
      { fieldName: 'leakages', object: gearbox, fieldValue: 'yes' },
    ];
    fieldsToChange.forEach(({ fieldName, object, fieldValue }) => {
      parseStringToBool(fieldName, object, fieldValue);
    });
  }

  const fieldsToChange = [
    { fieldName: 'manualApplication', object: before, fieldValue: 'manual' },
    { fieldName: 'machineRunning', object: before, fieldValue: 'running' },
    { fieldName: 'cleanBefore', object: before, fieldValue: 'yes' },
    { fieldName: 'manualApplication', object: after, fieldValue: 'manual' },
    { fieldName: 'machineRunning', object: after, fieldValue: 'running' },
    { fieldName: 'cleanBefore', object: after, fieldValue: 'yes' },
  ];

  fieldsToChange.forEach(({ fieldName, object, fieldValue }) => {
    parseStringToBool(fieldName, object, fieldValue);
  });

  const componentParams = technical[application.componentType] || null;

  FIELDS_TO_REMOVE_BEFORE_SEND.forEach((field) => unset(application, field));

  before.previousLubricants = filterEmptyValues(before.previousLubricants);

  return {
    ...application,
    technical: {
      ...application.technical,
      componentParams,
    },
  };
};
