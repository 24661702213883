import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { isPast } from 'date-fns';
import { DateTile, Divider, usePermissions } from 'frontend-components';
import NextActionsIcon from '../Icons/NextActionsIcon';
import Collapsible from '../Collapsible/Collapsible';
import ListItem from '../List/ListItem';
import ListItemText from '../List/ListItemText';
import ListItemSecondaryAction from '../List/ListItemSecondaryAction';
import LimitedList from '../LimitedList/LimitedList';
import Checkbox from '../Checkbox/Checkbox';
import { selectTAEntities } from '../../store/reducers/userSlice';
import { NOTE_BY_REPORT_TYPE, NOTE_TYPES } from '../../constants/noteTypes';
import { addNextActionToNoteMap, getActionIcon } from '../../helpers/actions';
import {
  putOpenAction,
  updateNextActionInOpenActions,
  deleteNextActionFromOpenActions,
} from '../../store/actions/nextActionActions';

const StyledListItem = styled(ListItem)`
  .MuiCheckbox-root {
    padding: 0 0 0 10px;
    align-self: flex-start;
    margin: 4px 8px 0 0;
  }

  .MuiFormControlLabel-root {
    width: 100%;

    .MuiFormControlLabel-label {
      width: 100%;
      padding-right: 30px;
    }
  }
`;

const TextAndDateWrapper = styled.div`
  width: calc(100% - 50px);
  padding-right: 50px;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    fill: #666;
    margin: 4px 8px 0 0;
    font-size: 20px;
  }
`;

const getActionType = (value, actionTypes, t) => {
  const matchingType = actionTypes.find((type) => type.value === value);
  return t(`${matchingType.label}`);
};

const ActionsList = ({
  title = null,
  expanded,
  onChange = () => {},
  actions,
  displayPlusButton = false,
  onPlusButtonClick = () => {},
  onItemClick,
  onCheckboxClick = null,
  'data-testid': testId,
  disabledAction,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { clientId, noteId } = useParams();
  const history = useHistory();
  const TAEntities = useSelector(selectTAEntities);
  const {
    actionTypes,
    visitsData,
    callsData,
    applicationNotesData,
    ownedClient,
    access,
  } = useSelector((state) => ({
    actionTypes: state.app.actionType,
    visitsData: state.clientData.data[clientId].visits.data,
    callsData: state.clientData.data[clientId].phoneCalls.data,
    applicationNotesData: state.clientData.data[clientId].applicationNotes.data,
    ownedClient: state.clientData.data[clientId].owned,
    access: state.clientData.data[clientId].access,
  }));

  // delete action from open actions after checking box on customer view and redirecting to note view
  useEffect(() => {
    if (onItemClick && actions.data[0].actionDone) {
      dispatch(deleteNextActionFromOpenActions(clientId, actions.data[0].id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { editDisabled: disabled } = usePermissions(access, ownedClient);
  const { userId } = useSelector((state) => ({ userId: state.user.data.id }));

  const handleActionUpdate = (value, action) => {
    onCheckboxClick && onCheckboxClick();

    const nextAction = {
      ...action,
      actionDone: value,
    };
    const noteType = NOTE_BY_REPORT_TYPE[action.reportType];

    const notMarkNoteAsEdited = disabled && userId === action.delegatedId;

    noteType &&
      dispatch(
        addNextActionToNoteMap[noteType](
          noteId,
          clientId,
          nextAction,
          notMarkNoteAsEdited
        )
      );

    // check if actions list is on note view
    if (onItemClick) {
      value && isPast(new Date(action.doAt))
        ? dispatch(deleteNextActionFromOpenActions(clientId, action.id))
        : dispatch(updateNextActionInOpenActions(clientId, nextAction));
    } else {
      dispatch(updateNextActionInOpenActions(clientId, nextAction));
    }

    const ActionCheckedFromVisitLevel = typeof noteId !== 'undefined';
    if (!ActionCheckedFromVisitLevel || disabled) {
      dispatch(putOpenAction(clientId, nextAction));
    }
  };

  const handleVisitOrCallActionClick = (noteData, nextActionId, noteType) => {
    const noteId = noteData.find(
      (note) => note.nextAction?.id === nextActionId
    ).id;
    history.push(`/customers/${clientId}/${noteType}/${noteId}`);
  };

  const handleApplicationNoteActionClick = (noteData, nextActionId) => {
    const applicationNote = noteData.find(
      (note) => note.nextAction?.id === nextActionId
    );
    const { applicationId } = applicationNote;
    history.push(`/customers/${clientId}/applications/${applicationId}`);
  };

  const goToNextActionView = (nextActionId, reportType) => {
    const { VISITS, CALLS, APPLICATIONS } = NOTE_TYPES;

    const noteType = NOTE_BY_REPORT_TYPE[reportType];

    if (noteType === VISITS) {
      handleVisitOrCallActionClick(visitsData, nextActionId, noteType);
    } else if (noteType === CALLS) {
      handleVisitOrCallActionClick(callsData, nextActionId, noteType);
    } else if (noteType === APPLICATIONS) {
      handleApplicationNoteActionClick(applicationNotesData, nextActionId);
    }
  };

  return (
    <Collapsible
      expanded={expanded}
      onChange={onChange}
      icon={<NextActionsIcon />}
      displayPlusButton={displayPlusButton}
      onPlusButtonClick={onPlusButtonClick}
      title={title || t('actions')}
      itemsSize={actions.total}
      collapsible={actions.total > 0}
      data-testid={testId}
      disabled={disabledAction}
    >
      <LimitedList
        items={actions.data}
        label={t('actions')}
        renderItem={(item, index) => {
          const isLast = index === actions.data.length - 1;
          const onClick = () =>
            onItemClick
              ? onItemClick(item)
              : goToNextActionView(item.id, item.reportType);
          const primary =
            item.action.length > 0
              ? item.action
              : getActionType(item.type, actionTypes, t);
          const secondary = t('assigned_to', {
            person:
              item.delegatedTaName ||
              TAEntities[item.delegatedId]?.name ||
              'unknown',
          });

          return (
            <Fragment key={item.id}>
              <StyledListItem
                button
                disabled={
                  disabled && userId !== item.delegatedId && !item.owned
                }
              >
                <Checkbox
                  checked={item.actionDone}
                  onChange={(value) => handleActionUpdate(value, item)}
                  disabled={disabledAction}
                />
                <TextAndDateWrapper onClick={onClick}>
                  <ListItemText primary={primary} />
                  <ActionWrapper>
                    {getActionIcon(item.type)}
                    <ListItemText secondary={secondary} />
                  </ActionWrapper>
                  <ListItemSecondaryAction $disabled>
                    <DateTile value={item.doAt} />
                  </ListItemSecondaryAction>
                </TextAndDateWrapper>
              </StyledListItem>
              {!isLast && <Divider />}
            </Fragment>
          );
        }}
      />
    </Collapsible>
  );
};

ActionsList.propTypes = {
  title: PropTypes.string,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  displayPlusButton: PropTypes.bool,
  onPlusButtonClick: PropTypes.func,
  onItemClick: PropTypes.func,
  onCheckboxClick: PropTypes.func,
  actions: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.string,
        delegatedId: PropTypes.string,
        doAt: PropTypes.string,
        id: PropTypes.string,
        contactPerson: PropTypes.string,
        updatedAt: PropTypes.string,
        actionDone: PropTypes.bool,
        duration: PropTypes.number,
        reportType: PropTypes.string,
      })
    ),
  }),
  'data-testid': PropTypes.string,
  disabledAction: PropTypes.bool,
};

export default ActionsList;
