import styled from 'styled-components';
import { default as MuiCard } from '@material-ui/core/Card';
import Search from '@material-ui/icons/Search';
import DraftIcon from '../Icons/DraftIcon';

export const DraftSwitch = styled.div`
  display: flex;
  padding: 24px 16px;
  flex-direction: column;

  & > div {
    flex: 1;
  }
`;

export const DraftMarker = styled(DraftIcon)`
  color: #cccccc;
  display: flex;
  align-self: ${({ $withLabel }) => ($withLabel ? 'center' : 'end')};
  margin: ${({ $withLabel }) =>
    $withLabel ? '3px 8px 3px 3px' : '3px 0 3px 3px'};
`;

export const SearchIcon = styled(Search)`
  color: #cccccc;
  display: flex;
  align-self: ${({ $withLabel }) => ($withLabel ? 'center' : 'end')};
  margin: ${({ $withLabel }) =>
    $withLabel ? '3px 8px 3px 3px' : '3px 0 3px 3px'};
`;

export const DraftCard = styled(MuiCard)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
`;

export const DraftSwitchRow = styled.div`
  display: flex;
`;

export const SwitchContainer = styled.div`
  flex: 1;
`;
