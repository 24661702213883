import { v4 as uuidv4 } from 'uuid';
import {
  sortByDateAsc,
  getDateFormatted,
} from 'frontend-components/lib/helpers';

const prepareCart = (packagingData) => {
  const arrOfProducts = Object.values(packagingData)
    .map((value) => value)
    .flat();

  return sortByDateAsc(arrOfProducts).map(({ code, quantity, price }) => {
    return {
      code,
      quantity,
      price,
    };
  });
};

export const prepareOrderPayload = (values, clientId) => {
  const {
    contactPersonId,
    packagingData,
    customerOrderNumber = '',
    note = '',
    emailNote = '',
    internalNote = '',
    acceptedTerms = false,
    acceptedConditions = false,
    signBinary = '',
    contactsCC = [],
    deliveryCost,
    hideTotals,
    sendConfirmation,
    discount,
    shareAllowed,
  } = values;

  return {
    clientId,
    customerOrderNumber,
    createdAt: getDateFormatted(new Date()),
    cart: prepareCart(packagingData),
    contactPersonId,
    note,
    emailNote,
    internalNote,
    acceptedTerms,
    acceptedConditions,
    signBinary,
    contactsCC,
    deliveryCost,
    hideTotals,
    sendConfirmation,
    discount,
    shareAllowed,
  };
};

export const prepareQuotationPayload = (values, clientId) => {
  const {
    contactPersonId,
    packagingData,
    customerOrderNumber = '',
    note = '',
    emailNote = '',
    internalNote = '',
    contactsCC = [],
    deliveryCost,
    hideTotals,
    sendConfirmation,
    discount,
    shareAllowed,
  } = values;

  return {
    clientId,
    customerOrderNumber,
    createdAt: getDateFormatted(new Date()),
    cart: prepareCart(packagingData),
    contactPersonId,
    note,
    emailNote,
    internalNote,
    contactsCC,
    deliveryCost,
    hideTotals,
    sendConfirmation,
    discount,
    shareAllowed,
  };
};

const prepareProducts = (packagingData) =>
  Object.values(packagingData)
    .map((value) => value)
    .flat();

export const prepareQuotationHistory = (values, currency) => {
  const { contactPersonId, packagingData, netPrice } = values;

  return {
    id: uuidv4(),
    createdAt: getDateFormatted(new Date()),
    products: prepareProducts(packagingData),
    contactPersonId,
    totalCost: netPrice,
    currency,
  };
};
