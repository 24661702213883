import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { startOfTomorrow } from 'date-fns';
import { getPlannedDate } from 'frontend-components/lib/helpers';

import {
  getClientListData,
  getClientListNearestData,
  getClientListPlannedData,
  getNextActionsList,
  getClientsActions,
} from '../../services/clients.service';
import { fetchProductsInCategories } from './products';
import {
  addNextActionToClient,
  deleteNextActionFromOpenActions,
  updateNextActionInOpenActions,
} from '../actions/nextActionActions';

export const fetchClientListData = createAsyncThunk(
  'clientListData/fetch',
  async () => {
    return getClientListData();
  }
);

export const fetchNextActionsList = createAsyncThunk(
  'eventsList/fetch',
  async ({ from, to }) => {
    return getNextActionsList(from, to);
  }
);

export const fetchClientListPlannedData = createAsyncThunk(
  'clientListPlannedData/fetch',
  async (date) => {
    return getClientListPlannedData(date);
  }
);

export const fetchClientListPlannedDataTomorrow = createAsyncThunk(
  'clientListPlannedData/fetchTomorrow',
  async (date) => {
    return getClientListPlannedData(date);
  }
);

export const fetchClientListNearestData = createAsyncThunk(
  'clientListNearestData/fetch',
  async ({ coords, page }) => {
    const { latitude, longitude } = coords;
    return getClientListNearestData(latitude, longitude, page);
  }
);

export const fetchMoreClientListNearestData = createAsyncThunk(
  'clientListMoreNearestData/fetch',
  async ({ coords, page }) => {
    const { latitude, longitude } = coords;
    return getClientListNearestData(latitude, longitude, page);
  }
);

export const fetchClientsActions = createAsyncThunk(
  'clientsActions/fetch',
  async ({ from, to }) => {
    return getClientsActions(from, to);
  }
);

export const updateClientDetails = createAction(
  'client/updateDetails',
  function prepare(clientId, clientData) {
    return {
      payload: {
        clientId,
        clientData,
      },
    };
  }
);

/* prettier-ignore */
export const deleteClient = createAction('client/delete', function prepare(
  clientId,
  coords
) {
  return {
    payload: {
      clientId,
    },
    meta: {
      offline: {
        effect: {
          url: `/qac/client/${clientId}`,
          method: 'DELETE',
        },
        commit: {
          type: 'client/delete/commit',
          meta: {
            clientId,
            after: [
              fetchClientListData,
              fetchClientListNearestData,
              fetchNextActionsList,
            ],
            afterArgs: [
              [null],
              [coords],
              [
                {
                  from: getPlannedDate(new Date()),
                  to: getPlannedDate(startOfTomorrow()),
                },
              ],
            ],
          },
        },
        rollback: {
          type: 'client/delete/rollback',
          meta: {
            clientId,
          },
        },
      },
    },
  };
});

/* prettier-ignore */
export const postClient = createAction(
  'client/post',
  function prepare(clientId, clientData) {
    return {
      payload: {
        clientId,
        clientData,
      },
      meta: {
        offline: {
          effect: {
            url: `/qac/client/${clientId}`,
            method: 'POST',
            data: clientData,
          },
          commit: {
            type: 'client/post/commit',
            meta: {
              clientId,
              clientData,
              after: fetchProductsInCategories,
              afterArgs: [{ clientId }],
            },
          },
          rollback: { type: 'client/post/rollback', meta: { clientId } },
        },
      },
    };
  }
);

/* prettier-ignore */
export const putClient = createAction(
  'client/put',
  function prepare(clientId, clientData) {
    return {
      payload: {
        clientId,
        clientData,
      },
      meta: {
        offline: {
          effect: {
            url: `/qac/client/${clientId}`,
            method: 'PUT',
            data: clientData,
          },
          commit: {
            type: 'client/put/commit',
            meta: {
              clientId,
              clientData,
            },
          },
          rollback: { type: 'client/put/rollback', meta: { clientId } },
        },
      },
    };
  }
);

/* prettier-ignore */
export const setSearchedClients = createAction(
  'client/search',
  function prepare(searchedClients) {
    return {
      payload: {
        searchedClients,
      },
    };
  }
);

export const addClientToSearch = createAction(
  'client/addClientToSearch',
  function prepare(clientListItemData) {
    return {
      payload: {
        clientListItemData,
      },
    };
  }
);

export const addClientToPlannedClients = createAction(
  'client/addClientToPlannedClients',
  function prepare(clientId, clientAction) {
    return {
      payload: {
        clientId,
        clientAction,
      },
    };
  }
);

export const deleteClientFromPlannedClients = createAction(
  'client/deleteClientFromPlannedClients',
  function prepare(clientId) {
    return {
      payload: {
        clientId,
      },
    };
  }
);

const clientsSlice = createSlice({
  name: 'customers',
  initialState: {
    clients: [],
    loadingClients: false,
    loadingNextActions: false,
    plannedNextActions: [],
    nearestClients: [],
    totalNearestClients: null,
    loadingNearestClients: false,
    searchedClients: [],
    loadingClientsActions: false,
    clientsActions: {
      total: 0,
      data: [],
    },
  },
  reducers: {},
  extraReducers: {
    [fetchClientListData.pending]: (state) => {
      state.loadingClients = true;
    },
    [fetchClientListData.fulfilled]: (state, action) => {
      state.loadingClients = false;
      state.clients = action.payload?.data;
    },
    [fetchClientListData.rejected]: (state) => {
      state.loadingClients = false;
    },
    [fetchNextActionsList]: (state) => {
      state.loadingNextActions = true;
    },
    [fetchNextActionsList.fulfilled]: (state, action) => {
      state.loadingNextActions = false;
      if (action.meta.arg.force) {
        state.plannedNextActions = action.payload.data;
      } else {
        const fetchedActions = action.payload.data.filter(({ id }) => {
          return !state.plannedNextActions.find((action) => action.id === id);
        });
        state.plannedNextActions = [
          ...state.plannedNextActions,
          ...fetchedActions,
        ];
      }
    },
    [fetchNextActionsList.rejected]: (state) => {
      state.loadingNextActions = false;
    },
    [fetchClientListNearestData]: (state) => {
      state.loadingNearestClients = true;
    },
    [fetchClientListNearestData.fulfilled]: (state, action) => {
      state.loadingNearestClients = false;
      state.nearestClients = action.payload.data;
      state.totalNearestClients = action.payload.total;
    },
    [fetchClientListNearestData.rejected]: (state) => {
      state.loadingNearestClients = false;
    },
    [fetchMoreClientListNearestData.fulfilled]: (state, action) => {
      state.nearestClients = [...state.nearestClients, ...action.payload.data];
      state.loadingNearestClients = false;
    },
    [updateClientDetails]: (state, action) => {
      const { clientId, clientData } = action.payload;
      const updateClientsList = (clients, clientId, clientData) =>
        clients.map((client) => {
          if (client.id === clientId) {
            return {
              ...client,
              ...clientData,
              visitAddress: {
                ...client.visitAddress,
                ...clientData.visitAddress,
              },
              edited: true,
            };
          }
          return client;
        });
      state.clients = updateClientsList(state.clients, clientId, clientData);
      state.nearestClients = updateClientsList(
        state.nearestClients,
        clientId,
        clientData
      );
      state.searchedClients = updateClientsList(
        state.searchedClients,
        clientId,
        clientData
      );
    },
    [putClient]: (state, action) => {
      const { clientId } = action.payload;
      const updateClientsList = (clients, clientId) =>
        clients.map((client) => {
          if (client.id === clientId) {
            return {
              ...client,
              edited: false,
            };
          }
          return client;
        });
      state.clients = updateClientsList(state.clients, clientId);
      state.nearestClients = updateClientsList(state.nearestClients, clientId);
    },
    [deleteClient]: (state, action) => {
      const { clientId } = action.payload;
      state.clients = state.clients.filter(({ id }) => id !== clientId);
      state.nearestClients = state.nearestClients.filter(
        ({ id }) => id !== clientId
      );
    },
    [setSearchedClients]: (state, action) => {
      state.searchedClients = action.payload.searchedClients;
    },
    [addClientToSearch]: (state, action) => {
      const { clientListItemData } = action.payload;
      state.clients.push({ ...clientListItemData });
    },
    [fetchClientsActions.pending]: (state) => {
      state.loadingClientsActions = true;
    },
    [fetchClientsActions.fulfilled]: (state, action) => {
      state.loadingClientsActions = false;
      state.clientsActions.data = action.payload.data;
      state.clientsActions.total = state.clientsActions.data.length;
    },
    [fetchClientsActions.rejected]: (state) => {
      state.loadingClientsActions = false;
    },

    [addNextActionToClient]: (state, action) => {
      const { nextAction, clientName } = action.payload;
      const matchingOpenAction = state.clientsActions.data.find(
        (item) => item.id === nextAction.id
      );
      const clientAction = {
        id: nextAction.id,
        clientName,
        description: nextAction.action,
        duration: nextAction.duration,
        doAt: nextAction.doAt,
        reportType: nextAction.reportType,
        actionType: nextAction.type,
      };
      if (!matchingOpenAction) {
        state.clientsActions.data.unshift(clientAction);
      } else {
        state.clientsActions.data = state.clientsActions.data.map((action) =>
          action.id === matchingOpenAction.id
            ? { ...matchingOpenAction, ...clientAction }
            : action
        );
      }
      state.clientsActions.total = state.clientsActions.length;
    },
    [updateNextActionInOpenActions]: (state, action) => {
      const { nextAction } = action.payload;

      const matchingPlannedNextAction = state.clientsActions.data.find(
        (item) => {
          return item.id === nextAction.id;
        }
      );

      if (matchingPlannedNextAction) {
        state.clientsActions.data = state.clientsActions.data.map((action) =>
          action.id === nextAction.id
            ? {
                ...action,
                doAt: nextAction.doAt,
                duration: nextAction.duration,
                description: nextAction.action,
                actionType: nextAction.type,
              }
            : action
        );
      }
    },
    [deleteNextActionFromOpenActions]: (state, action) => {
      const { actionId } = action.payload;
      const matchingOpenActionIdx = state.plannedNextActions.findIndex(
        (item) => item.id === actionId
      );
      if (matchingOpenActionIdx !== -1) {
        state.plannedNextActions.splice(matchingOpenActionIdx, 1);
      }
    },
  },
});

export default clientsSlice.reducer;
