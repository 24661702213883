import { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import DescriptionIcon from '@material-ui/icons/Description';
import { Button, usePermissions } from 'frontend-components';

import AppBar from '../../components/AppBar/AppBar.connected';
import ApplicationsList from '../../components/Lists/ApplicationsList';
import DemoedProductsList from '../../components/Lists/DemoedProductsList';
import ContactPeopleList from '../../components/Lists/ContactPeopleList';
import ActionsList from '../../components/Lists/ActionsList';
import AttachmentsList from '../../components/Lists/AttachmentsList';
import GalleryItemList from '../../components/Lists/GalleryItemList.connected';
import withClientData from '../../helpers/withClientData';
import OrdersList from '../../components/Lists/OrdersList';
import NotesList from '../../components/Lists/NotesList';
import {
  NOTE_BY_REPORT_TYPE,
  NOTE_TYPES,
  REPORT_TYPES,
} from '../../constants/noteTypes';
import {
  updateExpandedPanel,
  clearExpandedPanel,
} from '../../store/reducers/clientUI';
import { LIST_TYPES, NO_EXPANDED_PANEL } from '../../constants/lists';
import { CUSTOMER_VIEW_KEY } from '../../constants/video';
import { clientDataShape } from '../../helpers/clientDataPropTypes';
import { combineReports } from '../../helpers/reports';

const ListContainer = styled.div`
  padding-top: 16px;

  > div {
    margin-bottom: 8px;
  }
`;

const DetailsButton = styled(Button)`
  margin-left: 8px;
`;

const demoedProductsWithoutCalls = (demoedProducts) => {
  const filteredDemo = demoedProducts.data.filter(
    ({ reportType }) => reportType !== REPORT_TYPES.PHONE_CALL
  );

  return {
    total: filteredDemo.length,
    data: filteredDemo,
  };
};

const CustomerView = ({ clientData: data }) => {
  const history = useHistory();
  const { clientId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { expanded } = useSelector((state) => {
    return {
      expanded: state.clientUI.expandedPanel[clientId]
        ? state.clientUI.expandedPanel[clientId]
        : NO_EXPANDED_PANEL,
    };
  });

  const reports = combineReports(data);

  useEffect(() => {
    const { body } = document;
    body.style.backgroundColor = '#f2f2f2';

    return () => {
      body.style.backgroundColor = '#ffffff';
    };
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    dispatch(
      updateExpandedPanel({
        clientId,
        expandedPanel: isExpanded ? NO_EXPANDED_PANEL : panel,
      })
    );
  };

  const { addDisabled: disabled } = usePermissions(data.access, data.owned);

  const handleProductItemClick = ({ reportId, reportType }) => {
    if (reportType === REPORT_TYPES.VISIT) {
      history.push(`/customers/${clientId}/${NOTE_TYPES.VISITS}/${reportId}`);
    } else if (reportType === REPORT_TYPES.APPLICATION_NOTE) {
      const note = data.applicationNotes.data.find(
        (note) => note.id === reportId
      );
      if (note) {
        history.push(
          `/customers/${clientId}/${NOTE_TYPES.APPLICATIONS}/${note.applicationId}/notes/${reportId}`
        );
      }
    }
  };

  const handleGalleryButtonClick = () => {
    history.push(`/customers/${clientId}/gallery`);
  };

  const { clients, searchedClients, plannedClients, plannedClientsTomorrow } =
    useSelector((state) => state.clients);

  const clientListItemData = clients
    .concat(searchedClients)
    .concat(plannedClients)
    .concat(plannedClientsTomorrow)
    .find((client) => client?.id === clientId);
  const { generalPhoneNumber } = clientListItemData || {};

  return (
    <Container maxWidth="sm" data-testid="customer-view">
      <AppBar
        title={data ? data.name : ''}
        displayBackButton
        backButtonAction={() => {
          history.push('/customers');
          dispatch(clearExpandedPanel({ clientId }));
        }}
        elevated
        aside={
          <DetailsButton
            data-testid="details"
            theme="transparent"
            size="small"
            onClick={() => history.push(`/customers/${data.id}/details`)}
          >
            {t('client_details_button')}
          </DetailsButton>
        }
      />
      <ListContainer>
        <GalleryItemList
          data-testid="photos-collapsible"
          expanded={expanded === LIST_TYPES.PHOTOS}
          onChange={handleChange(LIST_TYPES.PHOTOS)}
          galleryItems={data.photos}
          disabled={disabled}
          onAddPhoto={() => {
            dispatch(
              updateExpandedPanel({
                clientId,
                expandedPanel: LIST_TYPES.PHOTOS,
              })
            );
          }}
          onPlusButtonClick={() => {
            history.push(`/customers/${clientId}/cameraView`, {
              from: CUSTOMER_VIEW_KEY,
            });
          }}
          onGalleryButtonClick={handleGalleryButtonClick}
        />
        <ApplicationsList
          data-testid="applications-collapsible"
          expanded={expanded === LIST_TYPES.APPLICATIONS}
          onChange={handleChange(LIST_TYPES.APPLICATIONS)}
          applications={data.applications}
          disabled={disabled}
          onPlusButtonClick={() =>
            history.push(`/customers/${clientId}/applications/${uuidv4()}`)
          }
        />
        <NotesList
          data-testid="reports-collapsible"
          expanded={expanded === LIST_TYPES.REPORTS}
          onChange={handleChange(LIST_TYPES.REPORTS)}
          notes={reports}
          disabled={disabled}
          title={NOTE_TYPES.REPORTS}
          icon={<DescriptionIcon />}
          listTestId="reports_list_item"
          onPlusButtonClick={() =>
            history.push(
              `/customers/${clientId}/${NOTE_TYPES.VISITS}/${uuidv4()}`
            )
          }
          showDemoLabel
          menuOptions={[
            {
              value: REPORT_TYPES.VISIT,
              label: t(`menu_visit`),
              icon: 'DriveEtaIcon',
            },
            {
              value: REPORT_TYPES.PHONE_CALL,
              label: t(`menu_call`),
              icon: 'PhoneIcon',
            },
            {
              value: REPORT_TYPES.REMARK,
              label: t(`menu_remark`),
              icon: 'InsertDriveFileIcon',
            },
          ]}
          onMenuOptionClick={(value) => {
            history.push(
              `/customers/${clientId}/${NOTE_BY_REPORT_TYPE[value]}/${uuidv4()}`
            );
          }}
          showFilters
        />
        <OrdersList
          data-testid="orders-collapsible"
          expanded={expanded === LIST_TYPES.ORDERS}
          onChange={handleChange(LIST_TYPES.ORDERS)}
          ordersChartData={data.orders}
          disabled={disabled}
        />
        <DemoedProductsList
          productItemClick={handleProductItemClick}
          data-testid="demoproducts-collapsible"
          expanded={expanded === 'demoedproducts'}
          onChange={handleChange('demoedproducts')}
          demoProducts={demoedProductsWithoutCalls(data.demoProducts)}
          disabled={disabled}
        />
        <ContactPeopleList
          data-testid="contacts-collapsible"
          expanded={expanded === LIST_TYPES.CONTACTS}
          onChange={handleChange(LIST_TYPES.CONTACTS)}
          contactPersons={data.contactPersons}
          filterDeletedContacts
          disabled={disabled}
          onPlusButtonClick={() => {
            history.push(`/customers/${clientId}/contacts/${uuidv4()}`);
          }}
          generalPhoneNumber={generalPhoneNumber}
          showSearch
        />
        <ActionsList
          data-testid="actions-collapsible"
          expanded={expanded === 'openactions'}
          onChange={handleChange('openactions')}
          actions={data.openActions}
          disabledAction={disabled}
        />
        <AttachmentsList
          data-testid="attachments-collapsible"
          expanded={expanded === 'attachments'}
          onChange={handleChange('attachments')}
          attachments={data.attachments}
          disabled={disabled}
        />
      </ListContainer>
    </Container>
  );
};

CustomerView.propTypes = {
  clientData: clientDataShape,
};

export default withClientData(CustomerView, { forceFetch: true });
