export const READ = 'R';
export const WRITE = 'W';
export const DELEGATED = 'D';
export const EDITABLE = 'E';

export const Permissions = {
  READ,
  WRITE,
  DELEGATED,
  EDITABLE,
};

export const isReadable = (access) => access === Permissions.READ;
export const isWritable = (access) => access === Permissions.WRITE;
export const isDelegated = (access) => access === Permissions.DELEGATED;
export const isEditable = (access) => access === Permissions.EDITABLE;
