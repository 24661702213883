import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Container from '@material-ui/core/Container';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import InputIcon from '@material-ui/icons/Input';
import CloseIcon from '@material-ui/icons/Close';
import { v4 as uuidv4 } from 'uuid';
import { Switch } from 'frontend-components';
import { getDateFormatted } from 'frontend-components/lib/helpers';

import TextArea from '../../components/TextArea/TextArea';
import Input from '../../components/Input/Input';
import GalleryItemList from '../../components/Lists/GalleryItemList.connected';
import ContactPeopleList from '../../components/Lists/ContactPeopleList';
import useFormikAutoSave from '../../components/FormikAutoSave/FormAutoSaveHook';
import { calculateFieldValue } from './calculateFieldValue';
import CostSavings from './CostSavings';
import {
  DraftSwitch,
  DraftMarker,
  DraftCard,
  DraftSwitchRow,
  SwitchContainer,
} from '../../components/DraftMarker/DraftMarker';
import NotesList from '../../components/Lists/NotesList';
import {
  APPLICATION_LIST_TYPES,
  PHOTOS_AFTER_GALLERY,
  PHOTOS_BEFORE_GALLERY,
  PHOTOS_GENERAL_GALLERY,
} from '../../constants/lists';
import { FORM_DEBOUNCE_MS } from '../../constants/forms';
import {
  blockForm,
  updateApplicationExpandedPanel,
} from '../../store/reducers/clientUI';
import {
  applicationDataShape,
  applicationNotesShape,
  contactPersonDataShape,
  photosDataShape,
} from '../../helpers/clientDataPropTypes';
import { APPLICATIONS_TYPES } from '../../constants/applicationScreen';
import MenuItem from '../../components/Select/MenuItem';
import Select from '../../components/Select/Select';

const OverviewContainer = styled.div`
  padding-top: 2px;
  margin-bottom: 110px;

  & .MuiTextField-root {
    margin: 20px 0 10px;
  }
`;

const PanelContainer = styled.div`
  margin: ${({ $marginBottom }) => ($marginBottom ? '8px 0 24px' : '8px 0 0')};
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
`;

const ConfidentialSwitch = styled(DraftSwitch)`
  padding: 10px 0 0;
`;

function filterApplicationContacts(contacts, contactsIds) {
  if (!contactsIds)
    return {
      total: 0,
      data: [],
    };

  const applicationContacts = contacts.data.filter((contact) => {
    return contactsIds.includes(contact.id);
  });

  const sortedContactsData = sortBy(applicationContacts, [
    ({ lastName }) => lastName.toLowerCase(),
  ]);

  return {
    total: applicationContacts.length,
    data: sortedContactsData,
  };
}

const OverviewTab = ({
  expandedPanel,
  setExpandedPanel,
  isAppNameMissing,
  setIsAppNameMissing,
  updateApp,
  deleteAppContactPerson,
  beforeTag,
  afterTag,
  applicationData: appData,
  contactPersonsData,
  galleryItemsData,
  overviewTag,
  disabled,
  applicationNotes,
  currency,
  applicationNumber,
}) => {
  const { t } = useTranslation();
  const { clientId, applicationId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isNameManuallyChanged, setIsNameManuallyChanged] = useState(null);
  const [draftDisabled, setDraftDisabled] = useState(true);
  const [isAppNameMissingForUpload, setIsAppNameMissingForUpload] =
    useState(false);
  const [appNameErrorMessage, setAppNameErrorMessage] = useState('');

  const updateForm = (values) => {
    updateApp(applicationId, clientId, values);
  };

  const { handleSubmit, values, setFieldValue, dirty } = useFormik({
    enableReinitialize: true,
    initialValues: {
      machineBrand: appData.machineBrand || '',
      machineType: appData.machineType || '',
      machineModel: appData.machineModel || '',
      componentType: appData.componentType || '',
      name: appData.name || '',
      costSavings:
        appData.costSavings || calculateFieldValue(appData, 'savings') || '',
      uptimeGain:
        appData.uptimeGain || calculateFieldValue(appData, 'uptimeGain') || '',
      laborTimeSaved:
        appData.laborTimeSaved ||
        calculateFieldValue(appData, 'laborTime') ||
        '',
      isDraft: isEmpty(appData) ? true : appData.isDraft,
      photos: appData.photos || [],
      description: appData.description || '',
      notes: appData.notes || [],
      isConfidential: appData.isConfidential || false,
    },
    onSubmit: () => {
      if (dirty) {
        updateForm(values);
      }
    },
  });
  const {
    machineBrand,
    machineType,
    machineModel,
    componentType,
    name,
    costSavings,
    uptimeGain,
    laborTimeSaved,
    isDraft,
    photos,
    description,
    isConfidential,
  } = values;

  useFormikAutoSave(values, handleSubmit, FORM_DEBOUNCE_MS, dirty);

  const handleExpandPanel = (panel) => (event, isExpanded) => {
    setExpandedPanel(!isExpanded ? panel : '');
  };

  const handleAddPhoto = (expandedPanel) => {
    dispatch(
      updateApplicationExpandedPanel({
        clientId,
        expandedPanel,
      })
    );
  };

  const handleGalleryPlusButtonClick = (from, defaultTag) => {
    history.push(
      `/customers/${clientId}/applications/${applicationId}/cameraView?tagId=${defaultTag}`,
      {
        from,
      }
    );
  };

  const handleGalleryButtonClick = (defaultTag, from) => {
    if (!name) {
      setIsAppNameMissingForUpload(true);
      return;
    }
    history.push(
      `/customers/${clientId}/applications/${applicationId}/gallery${
        defaultTag ? `?tagId=${defaultTag}` : ''
      }`,
      { from }
    );
  };

  useEffect(() => {
    if (name.length) {
      setIsAppNameMissing(false);
      setIsAppNameMissingForUpload(false);
    }
  }, [name, setIsAppNameMissing, setIsAppNameMissingForUpload]);

  useEffect(() => {
    name.length ? setDraftDisabled(false) : setDraftDisabled(true);
  }, [name, setDraftDisabled]);

  useEffect(() => {
    if (isAppNameMissingForUpload) {
      setAppNameErrorMessage(t('validation_app_name_video'));
    } else if (isAppNameMissing) {
      setAppNameErrorMessage(t('validation_app_name'));
    } else {
      setAppNameErrorMessage(t(''));
    }
  }, [isAppNameMissing, isAppNameMissingForUpload, t]);

  // clear isFormBlocked value in store on page refresh
  useEffect(() => {
    dispatch(blockForm(false));
  }, [dispatch]);

  useEffect(() => {
    if (appData) {
      const nameParts = [
        machineType,
        machineBrand,
        machineModel,
        componentType,
      ].filter((item) => item !== undefined && item !== '');

      setIsNameManuallyChanged(
        nameParts.join('.') !== name &&
          name !== `${t('application_name_ph')}.${applicationNumber}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, machineBrand, machineType, machineModel, componentType]);

  useEffect(() => {
    if (isNameManuallyChanged !== false) return;
    const nameParts = [
      machineType,
      machineBrand,
      machineModel,
      componentType,
    ].filter((item) => item !== undefined && item !== '');
    if (
      nameParts.length > 0 ||
      name !== `${t('application_name_ph')}.${applicationNumber}`
    )
      setFieldValue('name', nameParts.join('.'));
  }, [
    machineBrand,
    machineType,
    machineModel,
    componentType,
    isNameManuallyChanged,
    setFieldValue,
    appData,
    applicationNumber,
    name,
    t,
  ]);

  return (
    <Container maxWidth="sm" data-testid="application-general">
      <OverviewContainer>
        <form onSubmit={handleSubmit}>
          <Input
            id="machineType"
            label={t('app_machine_type')}
            onchange={(e) => {
              setFieldValue('machineType', e);
            }}
            value={machineType}
            placeholder={t('app_machine_type_ph')}
            fullWidth
            maxLength={200}
            disabled={disabled}
          />
          <Input
            id="machineBrand"
            label={t('app_brand')}
            onchange={(e) => {
              setFieldValue('machineBrand', e);
            }}
            value={machineBrand}
            placeholder={t('app_brand_ph')}
            fullWidth
            maxLength={200}
            disabled={disabled}
          />
          <Input
            id="machineModel"
            label={t('app_machine_model')}
            onchange={(e) => {
              setFieldValue('machineModel', e);
            }}
            value={machineModel}
            placeholder={t('app_machine_model_ph')}
            fullWidth
            maxLength={200}
            disabled={disabled}
          />
          <PanelContainer $marginBottom>
            {overviewTag && (
              <GalleryItemList
                key="photosGeneral"
                data-testid="photosGeneral"
                title={t('photos_general')}
                expanded={
                  expandedPanel === APPLICATION_LIST_TYPES.PHOTOS_GENERAL
                }
                onChange={handleExpandPanel(
                  APPLICATION_LIST_TYPES.PHOTOS_GENERAL
                )}
                galleryItems={galleryItemsData}
                filterByIds={photos}
                defaultTag={overviewTag.id.toString()}
                disabled={disabled}
                onAddPhoto={() =>
                  handleAddPhoto(APPLICATION_LIST_TYPES.PHOTOS_GENERAL)
                }
                onPlusButtonClick={() =>
                  handleGalleryPlusButtonClick(
                    APPLICATION_LIST_TYPES.PHOTOS_GENERAL,
                    overviewTag.id.toString()
                  )
                }
                valid={Boolean(name.trim().length)}
                onInvalid={() => {
                  setIsAppNameMissing(true);
                  setIsAppNameMissingForUpload(true);
                }}
                onGalleryButtonClick={() =>
                  handleGalleryButtonClick(
                    overviewTag.id.toString(),
                    PHOTOS_GENERAL_GALLERY
                  )
                }
              />
            )}
          </PanelContainer>
          <Select
            id="componentType"
            data-testid="componentType"
            label={t('app_type')}
            fullWidth
            value={componentType}
            onChange={(componentType) => {
              setFieldValue('componentType', componentType);
            }}
            disabled={disabled}
          >
            {APPLICATIONS_TYPES.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {t(label)}
              </MenuItem>
            ))}
          </Select>
          <Input
            id="applicationName"
            label={t('app_name')}
            onchange={(e) => {
              setFieldValue('name', e);
              if (draftDisabled) {
                setFieldValue('isDraft', false);
              }
              setIsNameManuallyChanged(true);
            }}
            value={name}
            fullWidth
            errorMessage={appNameErrorMessage}
            maxLength={100}
            disabled={disabled}
            rightIcon={<CloseIcon data-testid="removeAppName" />}
            onIconClick={() => {
              setFieldValue('name', '');
            }}
            multiline
            maxRows={2}
          />
          {beforeTag && (
            <PanelContainer $marginBottom>
              <GalleryItemList
                key="photosBefore"
                data-testid="photosBefore"
                title={t('photos_before')}
                expanded={
                  expandedPanel === APPLICATION_LIST_TYPES.PHOTOS_BEFORE
                }
                onChange={handleExpandPanel(
                  APPLICATION_LIST_TYPES.PHOTOS_BEFORE
                )}
                galleryItems={galleryItemsData}
                filterByIds={photos}
                defaultTag={beforeTag.id.toString()}
                disabled={disabled}
                onAddPhoto={() =>
                  handleAddPhoto(APPLICATION_LIST_TYPES.PHOTOS_BEFORE)
                }
                onPlusButtonClick={() =>
                  handleGalleryPlusButtonClick(
                    APPLICATION_LIST_TYPES.PHOTOS_BEFORE,
                    beforeTag.id.toString()
                  )
                }
                valid={Boolean(name.trim().length)}
                onInvalid={() => {
                  setIsAppNameMissing(true);
                  setIsAppNameMissingForUpload(true);
                }}
                onGalleryButtonClick={() =>
                  handleGalleryButtonClick(
                    beforeTag.id.toString(),
                    PHOTOS_BEFORE_GALLERY
                  )
                }
              />
            </PanelContainer>
          )}
          <TextArea
            id="description"
            label={t('app_desc_label')}
            placeholder={t('app_desc_placeholder')}
            fullWidth
            onChange={(e) => {
              setFieldValue('description', e.target.value);
            }}
            value={description}
            maxLength={2000}
            disabled={disabled}
            helpText=""
          />
          <CostSavings
            costSavings={costSavings}
            uptimeGain={uptimeGain}
            laborTimeSaved={laborTimeSaved}
            showSavings={false}
            currency={currency}
          />
          <PanelContainer>
            <ContactPeopleList
              data-testid="contacts-list"
              withRemoveButton
              expanded={expandedPanel === APPLICATION_LIST_TYPES.CONATCTS}
              onChange={handleExpandPanel(APPLICATION_LIST_TYPES.CONATCTS)}
              onPlusButtonClick={() => {
                history.push(
                  `/customers/${clientId}/applications/${applicationId}/addContactPerson`
                );
              }}
              onRemoveClick={(contactPersonId) => {
                deleteAppContactPerson(
                  contactPersonId,
                  applicationId,
                  clientId
                );
              }}
              contactPersons={filterApplicationContacts(
                contactPersonsData,
                appData.contacts
              )}
              title={t('app_contacts')}
              disabled={disabled}
            />
          </PanelContainer>
          {afterTag && (
            <PanelContainer>
              <GalleryItemList
                key="photosAfter"
                data-testid="photosAfter"
                title={t('photos_after')}
                expanded={expandedPanel === APPLICATION_LIST_TYPES.PHOTOS_AFTER}
                onChange={handleExpandPanel(
                  APPLICATION_LIST_TYPES.PHOTOS_AFTER
                )}
                galleryItems={galleryItemsData}
                filterByIds={photos}
                defaultTag={afterTag.id.toString()}
                disabled={disabled}
                onAddPhoto={() =>
                  handleAddPhoto(APPLICATION_LIST_TYPES.PHOTOS_AFTER)
                }
                onPlusButtonClick={() =>
                  handleGalleryPlusButtonClick(
                    APPLICATION_LIST_TYPES.PHOTOS_AFTER,
                    afterTag.id.toString()
                  )
                }
                valid={Boolean(name.trim().length)}
                onInvalid={() => {
                  setIsAppNameMissing(true);
                  setIsAppNameMissingForUpload(true);
                }}
                onGalleryButtonClick={() =>
                  handleGalleryButtonClick(
                    afterTag.id.toString(),
                    PHOTOS_AFTER_GALLERY
                  )
                }
              />
            </PanelContainer>
          )}
          <PanelContainer>
            <NotesList
              data-testid="applications-notes-collapsible"
              expanded={expandedPanel === APPLICATION_LIST_TYPES.NOTES}
              onChange={handleExpandPanel(APPLICATION_LIST_TYPES.NOTES)}
              notes={applicationNotes}
              disabled={disabled}
              title="app_notes"
              icon={<InputIcon />}
              listTestId="application_notes_list_item"
              onPlusButtonClick={() => {
                history.push(
                  `/customers/${clientId}/applications/${applicationId}/notes/${uuidv4()}`
                );
              }}
              itemClickPath={`/customers/${clientId}/applications/${applicationId}/notes`}
              showDemoLabel
            />
          </PanelContainer>
          <Input
            id="createdAt"
            label={t('creation_date')}
            value={getDateFormatted(new Date(appData.createdAt), 'dd/MM/yyyy')}
            fullWidth
            disabled
          />
          <ConfidentialSwitch>
            <DraftMarker $withLabel />
            <Switch
              id="isConfidential"
              label={t('app_confidential')}
              checked={isConfidential}
              setChecked={(value) => {
                setFieldValue('isConfidential', value);
              }}
              disabled={disabled}
            />
          </ConfidentialSwitch>
          <DraftCard>
            <DraftSwitch>
              <DraftSwitchRow>
                <DraftMarker $withLabel />
                <SwitchContainer>
                  <Switch
                    id="isDraft"
                    label={t('app_draft')}
                    checked={isDraft}
                    setChecked={(value) => {
                      setFieldValue('isDraft', value);
                    }}
                    disabled={disabled || draftDisabled}
                  />
                </SwitchContainer>
              </DraftSwitchRow>
            </DraftSwitch>
          </DraftCard>
        </form>
      </OverviewContainer>
    </Container>
  );
};

OverviewTab.propTypes = {
  expandedPanel: PropTypes.string,
  setExpandedPanel: PropTypes.func,
  isAppNameMissing: PropTypes.bool,
  setIsAppNameMissing: PropTypes.func,
  updateApp: PropTypes.func,
  deleteAppContactPerson: PropTypes.func,
  beforeTag: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  afterTag: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  applicationData: applicationDataShape,
  contactPersonsData: contactPersonDataShape,
  galleryItemsData: photosDataShape,
  overviewTag: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  applicationNotes: applicationNotesShape,
  currency: PropTypes.string,
  applicationNumber: PropTypes.number,
};

export default OverviewTab;
