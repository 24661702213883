import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PropTypes from 'prop-types';
import { AsideButton, usePermissions } from 'frontend-components';

import AppBar from '../../components/AppBar/AppBar.connected';
import Gallery from '../../components/Gallery/Gallery';
import { clientDataShape } from '../../helpers/clientDataPropTypes';

const GalleryView = ({ clientData, galleryPath, fromKey, backPath }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId, applicationId } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const tagId = searchParams.get('tagId');
  const { editDisabled: disabled } = usePermissions(clientData.access);

  const { photosFromDevice, loadingPhotosFromDevice, videos } = useSelector(
    (state) => ({
      photosFromDevice: state.photosFromDevice.addedPhotos[clientId] || [],
      loadingPhotosFromDevice: state.photosFromDevice.loading,
      videos: Object.values(state.video.upload),
    })
  );

  const combinePhotos = (photos, photosFromDevice = {}, videos = {}) => {
    return [...photos, ...photosFromDevice, ...videos];
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
    history.push(
      applicationId
        ? `/customers/${clientId}/applications/${applicationId}/cameraView?tagId=${tagId}`
        : `/customers/${clientId}/cameraView`,
      {
        from: fromKey,
      }
    );
  };

  const AppBarAside = disabled ? (
    <AsideButton component="span" disabled={disabled}>
      <CameraAltIcon />
    </AsideButton>
  ) : (
    <AsideButton
      component="span"
      onClick={(e) => {
        handleButtonClick(e);
      }}
    >
      <CameraAltIcon />
    </AsideButton>
  );

  return (
    <Container maxWidth="sm">
      <AppBar
        title={t('photo_gallery')}
        displayBackButton
        aside={AppBarAside}
        elevated
        backButtonAction={() => {
          history.push(backPath);
        }}
      />
      <Gallery
        applications={clientData.applications.data}
        photos={combinePhotos(clientData.photos.data, photosFromDevice, videos)}
        tagId={tagId}
        loading={loadingPhotosFromDevice}
        galleryPath={galleryPath}
      />
    </Container>
  );
};

GalleryView.propTypes = {
  clientData: clientDataShape,
  galleryPath: PropTypes.string,
  fromKey: PropTypes.string,
  backPath: PropTypes.string,
};

export default GalleryView;
